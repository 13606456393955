import { Component } from '@angular/core';
import { UserService } from '../../services/user.service';
import { CustomToastrServiceService } from 'src/app/modules/base/services/custom-toastr-service.service';

@Component({
  selector: 'app-user-privacy-policy',
  templateUrl: './user-privacy-policy.component.html',
})
export class UserPrivacyPolicyComponent {
  constructor(private userService: UserService, private customToastrService: CustomToastrServiceService) { }
  ngOnInit() {
    this.userService.getuserTotalPoint();

    // method for show custom toastr 
    const isEmailVerified = JSON.parse(sessionStorage.getItem('iEV'))
    if (isEmailVerified == false) {
      this.customToastrService.showCustomToastr('', '')
    }
  }
  showPrivacy = true;
  showTerms = false;
  showTermsDiv() {
    this.showTerms = true;
    this.showPrivacy = false;
  }
  showPrivacyDiv() {
    this.showPrivacy = true;
    this.showTerms = false;
  }
}
