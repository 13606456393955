import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { userApi } from 'src/app/base/model/constants/ApiEndPoints';
import { PanelGuId, PanelId } from 'src/app/base/model/constants/AppConstants';
import { BaseService } from 'src/app/base/service/base.service';
import { CustomToastrServiceService } from 'src/app/modules/base/services/custom-toastr-service.service';
import { UserService } from '../../services/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-profile-question',
  templateUrl: './profile-question.component.html'
})
export class ProfileQuestionComponent implements OnInit {
  constructor(private _baseService: BaseService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private customToastrService: CustomToastrServiceService,
    private userService: UserService,
    private translate: TranslateService
  ) { }
  selectedSinglePunch
  questions;
  isShowTab;
  profileQuestion;
  getCatagory;
  radioValues;
  checkboxValue;
  newProfileOption;
  checkBoxArray = [];
  getUserMultiCheck = [];
  singlePunchArray = [];
  isdefaultOption
  isDisabled = true;
  isShowQuestions = false;
  countryId
  languageId;
  errorMessage;
  isShowError = false;
  userProfileQuestionForm: FormGroup;
  storeQuestionArray: Array<any> = [];
  newCheckboxArray = [];
  isSubmit: boolean;
  ngOnInit() {
    this.countryId = localStorage.getItem('countryId');
    this.languageId = localStorage.getItem('languageId');
    // Method for get question category
    const panelGuid = PanelGuId.OpinionEdge;
    if (this.countryId && this.languageId) {
      this._baseService.get(userApi.getUserProfileCategory + `?panelGuid=${panelGuid}&countryId=${this.countryId}&languageId=${this.languageId}`).then((res: any) => {
        this.isShowQuestions = true;
        this.questions = res.data ? res.data : [res];
        this.isdefaultOption = res.data ? res.data[0] : [res][0];
        this.getQuestions(this.isdefaultOption?.categoryId, 0);

        this.questions.forEach((element,index) => {
          const category = element.category;
          this.translate.get(category.toLowerCase()).subscribe((translatedCategory: any) => {
            this.questions[index].category = translatedCategory
          })
        });
        
        this.userService.getuserTotalPoint();
      }).catch((err) => {
        console.log(err);

        // this.toastr.error(err.error.message)
      })
    } else {
      this.isShowError = true;
    }

    // method for show custom toastr 
    const isEmailVerified = JSON.parse(sessionStorage.getItem('iEV'))
    if (isEmailVerified == false) {
      this.customToastrService.showCustomToastr('', '')
    }

    this.userProfileQuestionForm = this.fb.group({
      userResponseValue: new FormControl('')
    })
  }



  // Method for get questions with tab click
  getQuestions(event, index) {
    this.isSubmit = false;
    this.isShowTab = index;
    this.getCatagory = event;
    const panelGuid = PanelGuId.OpinionEdge;
    const countryId = this.countryId;
    const languageId = this.languageId;
    const panelistId = localStorage.getItem('panelistId')
    this._baseService.get(userApi.getProfileCatQsn + `?panelGuid=${panelGuid}&countryId=${countryId}&languageId=${languageId}&categoryId=${event}`
    ).then((res: any) => {
      this.profileQuestion = res.data;
      this.newProfileOption = this.profileQuestion?.option;
      this.storeQuestionArray = res.data;
    }).catch((err) => {
      this.isShowError =true
    })
  }

  // Method for get user response 
  getUserAnswerValue(event, item, checkedEvent) {
    this.isSubmit = true;

    if (item.questiontype === 'Single Punch') {
      item.option.forEach(element => {
        element.isSelected = false;
      });
      event.isSelected = true;
      item.isSelected = true;
      this.newCheckboxArray.push(item)
    } else {
      event.isSelected = checkedEvent.target.checked;
      item.isSelected = true;
      this.newCheckboxArray.push(item)
    }
  }

  // Method for submit user response
  userResponseNew() {
    const newCheckboxArray = this.newCheckboxArray.reduce((acc, currentValue) => {
      if (!acc.includes(currentValue)) {
        acc.push(currentValue)
      }
      return acc;
    }, []);
    const body = {
      panelistId: Number(localStorage.getItem('panelistId')),
      userAnswer: newCheckboxArray
    }
    this._baseService.post(userApi.saveUserProfileResponse, body).then((res: any) => {
      this.isSubmit = false;
      this.translate.get('saveResponse').subscribe((translate: string) => {
        this.toastr.success(translate, '', {
          toastClass: 'success-toast',
          timeOut: 3000,
        })
      })
    }).catch((err) => {
      this.toastr.error(err.message)
    })
  }
}

