<header class="sticky-top">
  <nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light border-bottom box-shadow header">
    <div class="container-fluid">

      <a class="navbar-brand" [routerLink]="['/']">
        <img class="mx-1 logo-landingpage" src="../assets/icons/dashboard-logo-icon.png" />
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target=".navbar-collapse"
        aria-label="Toggle navigation" [attr.aria-expanded]="isExpanded" (click)="toggle()">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse d-sm-inline-flex justify-content-end" [ngClass]="{ show: isExpanded }">
        <ul class="navbar-nav flex-grow">
          <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
            <a class="nav-link text-dark" [routerLink]="['/']">{{'home'|translate}}</a>
          </li>
          <li class="nav-item" [routerLinkActive]="['link-active']">
            <a class="nav-link text-dark" [routerLink]="['/privacy-policy']">{{'privacyPolicy'|translate}}</a>
          </li>
          <li class="nav-item" [routerLinkActive]="['link-active']">
            <a class="nav-link text-dark" [routerLink]="['/faqs']">{{'faqs'|translate}}</a>
          </li>
          <li class="nav-item" [routerLinkActive]="['link-active']">
            <a class="nav-link text-dark" [routerLink]="['/contact-us']">{{'contactUs'|translate}}</a>
          </li>
          <li class="nav-item" [routerLinkActive]="['link-active']">
            <a class="nav-link join-link cursor-pointer"  (click)="getJoinNow()">{{'joinNow'|translate}}</a>
          </li>
        </ul>
      </div>
    </div>
    <hr />
  </nav>

</header>