<app-nav-menu></app-nav-menu>
<hr class=" container-fluid nav-devider">
<div class="container-fluid ">
  <div class="row contact-us">
    <h3 class="contactus-header"> {{'getInTouch'|translate}}</h3>
    <p class="contactus-summary">
      {{'contactTitle'|translate}}<br />
      {{'thankYou'|translate}}
      <br /><br />
      {{'teamOpinion'|translate}}
    </p>

    <div class="row">
      <div class="col-md-12">
        <div class="banner-box">
          <div class="row row-flex">
            <div class="col-12 col-lg-6">
               <div>
                <div class="mb-3 txt-group email">
                  <label class="form-label">
                    <span class="text-primary fs-6 mx-2"> <img src="../assets/icons/send image.png" /></span>
                    {{'emailId'|translate}}
                  </label>
                  <p>{{'contactEmail'|translate}}</p>
                </div>
                <div class="mb-3 email txt-group">
                  <label class="form-label">
                    <span class="text-primary fs-6 mx-2"> <i class="fa-solid fa-location-dot"></i></span>
                    {{'address'|translate}}
                  </label>
                  <p>{{'contactAddress'|translate}}</p>
                </div>
               </div>
            </div>
            <div  class="col-12 col-lg-6">
              <form [formGroup]="userSupportForm" (ngSubmit)="onSubmitrequest()">
                  <div class="form-box referwithemail">
                    <div class="row contact-textgroup">
                      <div class="col-md-6">
                        <div class="mb-2 txt-group">
                          <label class="form-label">{{'emailAddress'|translate}}</label>
                          <input type="email" formControlName="email" [placeholder]="'email'|translate" [ngClass]="userSupportForm.get('email').invalid && userSupportForm.get('email')?.touched ?'form-control form-input px-1':'form-control form-input'">
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-2 txt-group">
                          <label class="form-label">{{'subject'|translate}}</label>
                          <input type="text" formControlName="subject" [placeholder]="'subject'|translate" [ngClass]="userSupportForm.get('subject').invalid && userSupportForm.get('subject')?.touched ?'form-control form-input px-1':'form-control form-input'">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-md-12">
                        <div class="mb-2 txt-group">
                          <label class="input-group mb-3 support-filediv" for="picFile">
                            <span class="input-group-text">{{'chooseFile'|translate}}</span>
                            <span class="form-control pic-file-span addtext-ellipsis">{{ picFileName.length > 0 ? picFileName.join(', '):('noFile'|translate)}}</span>
                          </label>
                          <input type="file" formControlName="selectFile" id="picFile"  class="form-control text-secondary d-none" (change)="onFileSelected($event)" multiple>
                        </div>
                        <div class="row selected-files-div">
                          <div *ngFor="let fileName of picFileName; let i = index" class="filePhoto">
                            <div class="cancel-img" (click)="cancelSelectedPhoto(i)">
                              <i class="fa-solid fa-circle-xmark"></i>
                            </div>
                            <ng-container *ngIf="selectedFile[i] && isImageType(selectedFile[i].file.type)">
                              <img *ngIf="selectedFile[i]" [src]="selectedFile[i].dataUrl" [alt]="picFileName[i]">
                          </ng-container>
                          <ng-container *ngIf="selectedFile[i] && !isImageType(selectedFile[i].file.type)">
                            <div class="unknown-filetype">
                              <img src="../../../../../assets/image/Docs.png" alt="Docs">
                            </div>
                          </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row txt-group">
                      <label class="form-label">{{'description'|translate}}</label>           
                        <textarea formControlName="textMessage" class="form-control col-12 col-md-6 text-area" [placeholder]="'description'|translate"></textarea>           
                    </div>
                    <!-- <div class="row">
                      <div class="px-2">
                        <ngx-recaptcha2 [siteKey]="recaptchaSiteKey" [hl]="getCulture"
                          formControlName="recaptcha" class="recaptcha">
                        </ngx-recaptcha2>
                      </div>
                    </div> -->
                    <div class="row mt-3">
                      <div class="col-md-3">
                        <button [disabled]="userSupportForm.invalid" type="submit" class="btn btn-primary">{{'submit'|translate}}</button>
                      </div>
                      <div class="col">
                        <span class="text-secondary">{{ticketResponse}}</span>
                      </div>
                    </div>
                  </div>
              </form>
            </div>
  
          </div>
        </div>
      </div>
  
    </div>
  </div>
  
</div>

<!-- <app-footer class="privacy-policyfooter"></app-footer> -->