<footer class="footer d-flex justify-content-between">
  <div class="fs-6">
    <span class="text-primary px-2 fs-6"><i class="fa-sharp fa-solid fa-circle"></i></span>
    {{'cashInYourPoint'|translate}}
  </div>
  <div class="getcustomize-footer fs-6">
    <span class="text-primary px-2 fs-6"><i class="fa-sharp fa-solid fa-circle"></i></span>
    {{'getCustomizedSurvey'|translate}}
  </div>
  <div class="fs-6">
    <span class="text-primary px-2 fs-6"><i class="fa-sharp fa-solid fa-circle"></i></span>
    {{'cashInYourPoint'|translate}}
  </div>
</footer>