import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { userApi } from 'src/app/base/model/constants/ApiEndPoints';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private refreshTokenUrl = environment.baseUrl + userApi.refreshtokenauth; //  refresh token api endpoint

  constructor(private http: HttpClient) { }

  // Method to perform a token refresh
  refreshToken(): Observable<any> {
    const refreshToken = this.getRefreshToken(); //get the refresh token
    if (!refreshToken) {
      return throwError('No refresh token available');
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${refreshToken}`
    });

    const options = {
      headers: headers
    };

    return this.http.post<any>(this.refreshTokenUrl, {}, options).pipe(
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  getRefreshToken(): string | null {
    return localStorage.getItem('refresh_token');
  }
  getAccessToken(): string | null {
    return localStorage.getItem('access_token')
  }
}
