import { Component } from '@angular/core';

@Component({
  selector: 'app-body-page',
  templateUrl: './body-page.component.html'
})
export class BodyPageComponent {

  constructor() { }

  ngOnInit() {
  
  }

  showDashboard: boolean = false;
}
