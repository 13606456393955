import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import jwt_decode from "jwt-decode";
@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {

  constructor(private router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // const token = localStorage.getItem('access_token');
    const token = localStorage.getItem('access_token');
    if (token) {
      if (token !== "null") {
        return true
      } else {
        return false;
      }
      // try {
        
      //   const decodedToken: any = jwt_decode(token);
      //   if (decodedToken.exp && decodedToken.exp * 1000 < Date.now()) {
      //     // Token has expired
      //     localStorage.clear();
      //     this.router.navigate(['/login']);
      //     return false;
      //   }
      //   return true;
      // }
      // catch (error) {
      //    // Invalid token
      //   localStorage.clear();
      //   this.router.navigate(['/login']);
      //   return false;
      // }
    }
    else {
      // Token not present, redirect to login
      this.router.navigate(['/login']);
      return false;
    }

  }

}
