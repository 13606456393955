import { Component, OnInit } from '@angular/core';
import { BaseService } from 'src/app/base/service/base.service';
import { userApi } from 'src/app/base/model/constants/ApiEndPoints';
import { PointTypeId } from 'src/app/base/model/constants/AppConstants';
import { UserService } from '../../services/user.service';
import { CustomToastrServiceService } from 'src/app/modules/base/services/custom-toastr-service.service';
import { TranslateService } from '@ngx-translate/core';
import { transition } from '@angular/animations';

@Component({
  selector: 'app-point-detail',
  templateUrl: './point-detail.component.html'
})
export class PointDetailComponent implements OnInit {

  constructor(private _baseService: BaseService, private userService: UserService, private customToastrService: CustomToastrServiceService,private translateService:TranslateService) { }
  getPanelistId;
  totalPoint = 0;
  totalSurveyPoint = 0;
  totalReferPoint = 0;
  completeSurvey = 0;
  sentReferral = 0;
  availablePoints = 0;
  userPoint;
  surveyData;
  referHistory;
  showEmptyMessage = false;
  emptyReferralMessage = false;
  errorMessage: string;
  getPointErrorMessage: string;
  sentRecentReferral=0;
  ngOnInit() {

    this.getPanelistId = localStorage.getItem('panelistId')
    this._baseService.get(userApi.getSurveyHistoryApi).then((res: any) => {
      this.completeSurvey = res.surveyComplete ? res.surveyComplete : 0;
      this.userService.callUserAPI.next(true)
      this.getPanelistPoint();
      this.getReferaFriend();
      this.getPanelistPointHistory();
      this.getReferralHistory();
    })
    
    // method for show custom toastr 
    const isEmailVerified = JSON.parse(sessionStorage.getItem('iEV'))
    if (isEmailVerified == false) {
      this.customToastrService.showCustomToastr('', '')
    }
  }

  getPanelistPoint() {
    this._baseService.get(userApi.getPanelistPoints).then((res: any) => {
      this.userPoint = res;
      this.totalPoint = this.userPoint.totalPoints;
      this.availablePoints = res?.availablePoints;
      this.userService.sendPoints.next(this.totalPoint);
      this.userService.sendCountryCode.next(res?.countryCode)
      this.userService.sendUserLevel.next(res?.levelName);
      this.userService.sendUserminPoint.next(res?.minPoint);
    })
  }

  getReferaFriend() {
    this._baseService.get(userApi.referaFriend).then((res: any) => {
      this.sentRecentReferral = res.data ? res.data.length : [res].length;
    }).catch((err) => {
      this.errorMessage = err.message;
    })
  }

  // Method for Survey Point History
  getPanelistPointHistory() {
    this._baseService.get(userApi.getPanelistPointHistory + `?pointTypeId=${PointTypeId.getForSurvey}`).then((result: any) => {
      this.surveyData = result.data ? result?.data : [result];
      this.surveyData.forEach((element, index) => {
        this.totalSurveyPoint += element?.points;
        const date = new Date(element.updatedAt);
        const formattedDate = this.userService.formatDate(date);
        this.surveyData[index].formattedDate = formattedDate;
      });
    }).catch((err) => {
      this.translateService.get('noRecordFound').subscribe((translation: string) => {
        this.getPointErrorMessage = translation;
      })
    })
  }

  // Method for getReferralHistory
  getReferralHistory() {
    this._baseService.get(userApi.getReferralHistory).then((res: any) => {
      this.referHistory = res.data ? res.data : [res];
      this.referHistory.forEach((element,index) => {
        this.totalReferPoint += element?.points;
        const date = new Date(element.joinDate);
        const formattedDate = this.userService.formatDate(date);
        this.referHistory[index].formattedDate = formattedDate;
      });
      if (this.referHistory.length === 0) {
        this.translateService.get('noRecordFound').subscribe((data: any) => {
          this.errorMessage = data;
        })
      } else {
        this.errorMessage = ''
      }
    }).catch((err) => {
      // this.errorMessage = err.message;
    })
  }


  /*cardClickTabs*/
  showPoints = false;
  showCompleteSurvey = false;
  showSentReferrals = false;
  showAvailablePoint = false;
  getpopUp() {
    this.showPoints = !this.showPoints;
    this.showCompleteSurvey = false;
    this.showSentReferrals = false;
    this.showAvailablePoint = false;
  }
  getCompleteSurvey() {
    this.showCompleteSurvey = !this.showCompleteSurvey;
    this.showPoints = false;
    this.showSentReferrals = false;
    this.showAvailablePoint = false;
  }
  getSentReferrals() {
    this.showSentReferrals = !this.showSentReferrals;
    this.showPoints = false;
    this.showCompleteSurvey = false;
    this.showAvailablePoint = false;
  }
  getAvailablePoint() {
    this.showAvailablePoint = !this.showAvailablePoint;
    this.showPoints = false;
    this.showCompleteSurvey = false;
    this.showSentReferrals = false;
  }

  /*  method for Tab selection  */
  surveyPointer = true;
  referalPoint = false;
  pollPointer = false;
  getTabSelection(value: any) {
    if (value === "surveyPoint") {
      this.surveyPointer = true;
      this.referalPoint = false;
      this.pollPointer = false;

    } else if (value === "referalPoint") {
      this.surveyPointer = false;
      this.referalPoint = true;
      this.pollPointer = false;

    } else if (value === "pollPoint") {
      this.surveyPointer = false;
      this.referalPoint = false;
      this.pollPointer = true;

    }

  }
}
