import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html'
})
export class PrivacyPolicyComponent {
  constructor(private router: Router) { }

  isShowContent: boolean
  ngOnInit() {
    if (localStorage.getItem('access_token')) {
      this.router.navigate(['login/body/dashboard'])
    }

    if (this.router.url === '/privacy-policy/terms-conditions') {
      this.showTerms = true;
      this.showPrivacy = false;
    }
  }
  showPrivacy = true;
  showTerms = false;
  showPrivacyDiv() {
    this.showPrivacy = true;
    this.showTerms = false;
  }
  showTermsDiv() {
    this.showTerms = true;
    this.showPrivacy = false;
  }

}
