import { Component } from '@angular/core';

@Component({
  selector: 'app-shopping',
  templateUrl: './shopping.component.html'
})
export class ShoppingComponent {
  items: string[] = [
    'Do you have a car', 'what is your car name', 'what is your car model'
  ]

}
