import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { userApi } from 'src/app/base/model/constants/ApiEndPoints';
import { PanelGuId } from 'src/app/base/model/constants/AppConstants';
import { BaseService } from 'src/app/base/service/base.service';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html'
})
export class UnsubscribeComponent implements OnInit {
  constructor(
    private ActivateRoute: ActivatedRoute,
    private _baseService: BaseService,
    private toastr:ToastrService,
    private translateService: TranslateService
  ) { }
  userPanelGuid;
  isShowSubscribe:boolean = false;
  ngOnInit() {
    this.ActivateRoute.queryParams.subscribe((params) => {
      const queryParamsToken = params['token'];
      const newParamsToken = encodeURIComponent(queryParamsToken)
      this.getUnSubscribe(newParamsToken);
    })

  }

  getUnSubscribe(queryParamsToken) {
    this._baseService.get(userApi.unsubscribeLanding + `?token=${queryParamsToken}`).then((res: any) => {
      this.userPanelGuid = res.panelistGuid;
    }).catch(err => { })
  }
  unSubscribeEmail() {
        const body = {
      unsubscribe: true,
      panelistGuid: this.userPanelGuid
    }
    this._baseService.post(userApi.unsubscribeEmail, body).then((res: any) => {
      this.translateService.get('emailUnsubscribed').subscribe((translate)=>{
        this.toastr.success(translate, '', {
          toastClass: 'success-toast',
          timeOut: 3000,
        })
      })
      this.isShowSubscribe = true
    }).catch(err => { })
  }
}
