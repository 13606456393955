<div class="container-fluid">
  <div class="accordion accordion-flush">
    <div class="accordion-item"  *ngFor="let item of profileQuestion">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed mb-3" type="button" data-bs-toggle="collapse" data-bs-target="#divTechno1"
          aria-expanded="false" aria-controls="divTechno1">
          {{item.qsnStatement}}
          <!-- {{'questionMessage'|translate}} -->
        </button>
      </h2>
      <div id="divTechno1" class="accordion-collapse collapse mt-2">
        <div class="row questions" *ngFor="let options of item.option">
          <div class="form-check mb-3">
            <label class="form-check-label">
              <input class="form-check-input" type="checkbox" value="">
              {{options.optionStatement}}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="text-end">
    <button class="btn btn-primary btn-sm questionsubmit-btn">Submit</button>
  </div>
</div>