<header class="sticky-top">
  <nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light border-bottom box-shadow header">
    <div class="container-fluid">
      <a class="navbar-brand">
        <img class="mx-1 logo-landingpage" src="../assets/icons/dashboard-logo-icon.png" />
      </a>
    </div>
    <hr />
  </nav>
</header>
<div class="center-div mx-width-700 pt-5">
  <div class="unverified-email">
    <div *ngIf="statusCode !== null && statusCode !== undefined">
      <img *ngIf="statusCode === 0" class="rd-level-img mb-2" src="../../../../../assets/image/oops.png" alt="image">
      <div *ngIf="statusCode === 2">
        <svg class="mb-2" xmlns="http://www.w3.org/2000/svg" width="85px" height="85px"
          shape-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" viewBox="0 0 512 512"
          xmlns:v="https://vecta.io/nano">
          <path fill="#358cd9"
            d="M141.5-.5h8c31.305 39.532 44.805 84.532 40.5 135-4.225 6.858-9.225 7.525-15 2 2.792-46.607-9.541-88.607-37-126-1.118-4.423.049-8.09 3.5-11z"
            opacity=".954" />
          <path fill="#ffabcd"
            d="M497.5-.5h11a6.54 6.54 0 0 0 3 3v9a10.51 10.51 0 0 0-3 2.5c-16.407 2.687-31.407 8.687-45 18-7.672 6.19-12.672 14.023-15 23.5 18.074.187 33.241 6.853 45.5 20 9.613 21.269 3.447 35.769-18.5 43.5-15.747 2.043-27.581-3.79-35.5-17.5a174.04 174.04 0 0 1-9.5-26c-17.14 8.64-30.14 21.473-39 38.5-7.333 3.491-11.999 1.324-14-6.5 11.235-23.735 29.068-40.402 53.5-50 2.723-15.612 10.223-28.445 22.5-38.5 13.375-9.523 28.042-16.023 44-19.5zm-50 72c10.616.31 20.283 3.477 29 9.5 8.925 9.11 7.592 16.444-4 22-4.816 1.027-9.15.027-13-3-6.814-8.263-10.814-17.763-12-28.5z"
            opacity=".954" />
          <path fill="#fad989"
            d="M303.5 31.5c-21.791 16.608-30.624 38.608-26.5 66a229.52 229.52 0 0 0 6.5 25c-5.407 9.651-12.24 10.985-20.5 4a687.6 687.6 0 0 1-8-32 268.25 268.25 0 0 1-18-21c-2.489-8.547-.322-15.714 6.5-21.5a230.51 230.51 0 0 0 22-10A122.97 122.97 0 0 0 281 20.5c5.741-7.094 12.074-7.76 19-2a27.1 27.1 0 0 1 3.5 13z" />
          <path fill="#fbf097"
            d="M303.5 31.5a229.77 229.77 0 0 0 5.5 20l17 19c3.092 8.72 1.259 16.22-5.5 22.5l-22 10a394.2 394.2 0 0 0-15 19.5 229.52 229.52 0 0 1-6.5-25c-4.124-27.392 4.709-49.392 26.5-66z"
            opacity=".982" />
          <path fill="#00d2b4"
            d="M104.5 60.5l1 2c-10.849 15.411-14.349 32.411-10.5 51a103.49 103.49 0 0 0 8.5 22c-.363.683-.696 1.35-1 2-18.573.801-31.739-7.199-39.5-24-7.156-24.18.678-41.347 23.5-51.5a67.62 67.62 0 0 1 18-1.5z" />
          <path fill="#00dcc4"
            d="M104.5 60.5c20.486 4.656 31.486 17.489 33 38.5-1.713 21.88-13.38 34.713-35 38.5.304-.65.637-1.317 1-2a103.49 103.49 0 0 1-8.5-22c-3.849-18.589-.349-35.589 10.5-51-.363-.684-.696-1.35-1-2z"
            opacity=".982" />
          <path fill="#68a2df"
            d="M361.5 136.5c23.358.857 31.858 12.523 25.5 35-8.774 11.985-19.94 14.819-33.5 8.5-12.439-10.853-13.939-23.019-4.5-36.5 3.739-3.281 7.905-5.615 12.5-7z"
            opacity=".973" />
          <path fill="#ff83b4"
            d="M19.5 151.5c12.823-.011 18.99 6.322 18.5 19-4.348 12.231-12.515 15.731-24.5 10.5-8.882-8.374-9.215-17.04-1-26l7-3.5z"
            opacity=".967" />
          <path fill="#ffca8b"
            d="M254.5 182.5c15.596-.577 22.096 6.757 19.5 22-4.554 8.079-11.387 10.913-20.5 8.5-9.803-5.451-12.303-13.284-7.5-23.5 2.416-2.923 5.25-5.257 8.5-7z"
            opacity=".953" />
          <path fill="#378cd8"
            d="M221.5 232.5c-5.584 1.795-11.584 2.629-18 2.5-7.644 1.356-14.978 3.523-22 6.5-14.48-9.236-30.147-15.736-47-19.5-10.582-2.318-19.416.515-26.5 8.5-2.954 8.859-3.288 17.859-1 27 5.042 16.081 12.208 31.081 21.5 45-2.18 13.236-4.846 26.236-8 39-19.55-23.091-33.383-49.424-41.5-79-1.806-7.223-2.639-14.556-2.5-22-.376-26.442 12.291-42.275 38-47.5 17.986-1.621 35.319 1.046 52 8 19.927 7.956 38.26 18.456 55 31.5z" />
          <path fill="#fbf198"
            d="M432.5 203.5c14.232-.638 28.232.862 42 4.5 7.501 3.335 8.835 8.335 4 15-1.778.704-3.612 1.204-5.5 1.5-14.584-4.595-29.418-5.761-44.5-3.5-10.525 1.925-19.192 6.925-26 15 15.065 7.395 25.565 18.895 31.5 34.5 2.911 21.425-6.422 33.092-28 35-16.616-.982-26.616-9.648-30-26a87.26 87.26 0 0 1 2.5-32c-8.548-1.534-16.881-.701-25 2.5-9.224 3.279-17.891 7.612-26 13-8.119 1.875-11.952-1.292-11.5-9.5.583-1.502 1.416-2.835 2.5-4 20.466-14.155 43.132-20.822 68-20 11.514-15.424 26.847-24.09 46-26zm-38 47c9.895 4.394 17.395 11.394 22.5 21 3.61 12.729-1.224 18.562-14.5 17.5-8.075-4.144-11.742-10.811-11-20a69.24 69.24 0 0 1 3-18.5z"
            opacity=".954" />
          <path fill="#f9d889"
            d="M181.5 241.5v1c-5.525 1.429-10.525 3.929-15 7.5-17.423 14.356-29.756 32.19-37 53.5 0-.667-.333-1-1-1-9.292-13.919-16.458-28.919-21.5-45-2.288-9.141-1.954-18.141 1-27 7.084-7.985 15.918-10.818 26.5-8.5 16.853 3.764 32.52 10.264 47 19.5z" />
          <path fill="#68a2de"
            d="M221.5 232.5c14.562 11.223 28.229 23.557 41 37-4.65 7.293-8.817 14.959-12.5 23a38.47 38.47 0 0 1-5.5 5c-18.28-21.625-39.28-39.958-63-55v-1c7.022-2.977 14.356-5.144 22-6.5 6.416.129 12.416-.705 18-2.5z"
            opacity=".992" />
          <path fill="#faf098"
            d="M181.5 242.5c23.72 15.042 44.72 33.375 63 55-8.736 4.452-16.903 9.785-24.5 16-2.732 6.059-1.232 10.892 4.5 14.5 9.725 2.847 19.391 5.847 29 9 6.366 6.031 12.366 12.364 18 19 1.525 1.009 3.192 1.509 5 1.5 2.448.973 5.115 1.306 8 1 13.635 38.855.635 53.355-39 43.5-23.794-10.391-45.461-24.058-65-41-19.379-17.204-36.379-36.37-51-57.5 7.244-21.31 19.577-39.144 37-53.5 4.475-3.571 9.475-6.071 15-7.5z" />
          <path fill="#f8d889"
            d="M76.5 240.5c-.139 7.444.694 14.777 2.5 22 8.117 29.576 21.95 55.909 41.5 79l1 2c-8.537 9.379-15.87 19.713-22 31h-1c-17.497-21.643-32.497-44.976-45-70a1812.34 1812.34 0 0 1 23-64z" />
          <path fill="#fe84b4"
            d="M292.5 279.5c-15.79 14.927-22.624 33.261-20.5 55 1.075 7.791 2.575 15.457 4.5 23-1.808.009-3.475-.491-5-1.5-5.634-6.636-11.634-12.969-18-19-9.609-3.153-19.275-6.153-29-9-5.732-3.608-7.232-8.441-4.5-14.5 7.597-6.215 15.764-11.548 24.5-16a38.47 38.47 0 0 0 5.5-5c3.683-8.041 7.85-15.707 12.5-23 13.147-6.062 23.147-2.729 30 10z" />
          <path fill="#feaacc"
            d="M292.5 279.5c3.131 2.629 6.131 5.462 9 8.5l29 9c6.975 5.251 7.642 11.251 2 18l-24 14c-1.641 1.129-2.641 2.629-3 4.5-.876-.131-1.543.202-2 1l-10.5 19c-2.746 1.955-5.58 3.622-8.5 5-2.885.306-5.552-.027-8-1-1.925-7.543-3.425-15.209-4.5-23-2.124-21.739 4.71-40.073 20.5-55z"
            opacity=".988" />
          <path fill="#00ddc4"
            d="M475.5 289.5c19.647-.852 29.147 8.481 28.5 28-4.668 15.255-14.835 21.088-30.5 17.5-12.266-5.021-17.433-14.188-15.5-27.5 2.71-9.208 8.543-15.208 17.5-18z"
            opacity=".967" />
          <path fill="#3a8dd7"
            d="M53.5 304.5c12.503 25.024 27.503 48.357 45 70-2.67 3.512-5.17 7.178-7.5 11-.671.752-1.504 1.086-2.5 1-16.547-18.756-30.88-39.089-43-61 2.294-7.227 4.961-14.227 8-21z"
            opacity=".995" />
          <path fill="#69a2de"
            d="M128.5 302.5c.667 0 1 .333 1 1 14.621 21.13 31.621 40.296 51 57.5 19.539 16.942 41.206 30.609 65 41 39.635 9.855 52.635-4.645 39-43.5 2.92-1.378 5.754-3.045 8.5-5l10.5-19c.457-.798 1.124-1.131 2-1 7.862 16.615 12.195 34.115 13 52.5-.296 31.784-16.296 47.951-48 48.5-14.424-.605-28.424-3.438-42-8.5-42.489-18.657-78.156-46.157-107-82.5l-1-2c3.154-12.764 5.82-25.764 8-39z"
            opacity=".995" />
          <path fill="#f8d889"
            d="M45.5 325.5c12.12 21.911 26.453 42.244 43 61l1 2a200.28 200.28 0 0 0-19 27l-2-1a439.19 439.19 0 0 1-37-49c4.467-13.402 9.134-26.735 14-40z" />
          <path fill="#68a2de"
            d="M369.5 342.5c45.779 3.422 87.112 18.589 124 45.5 3.536 3.201 6.703 6.701 9.5 10.5.438 8.224-3.396 11.391-11.5 9.5-35.239-28.437-75.572-45.103-121-50-4.724-2.435-6.224-6.269-4.5-11.5.69-1.85 1.856-3.184 3.5-4z"
            opacity=".964" />
          <path fill="#faf098"
            d="M121.5 343.5c28.844 36.343 64.511 63.843 107 82.5 13.576 5.062 27.576 7.895 42 8.5-21.062 8.187-42.395 15.854-64 23-41.349-20.194-77.015-47.86-107-83 6.13-11.287 13.463-21.621 22-31z"
            opacity=".996" />
          <path fill="#398cd8"
            d="M31.5 365.5a439.19 439.19 0 0 0 37 49c-1.49 5.649-4.49 10.316-9 14-12.396-13.822-24.062-28.156-35-43 1.813-6.936 4.146-13.603 7-20z"
            opacity=".995" />
          <path fill="#6aa3de"
            d="M98.5 374.5h1c29.985 35.14 65.651 62.806 107 83a196.39 196.39 0 0 1-21 8c-36.067-20.38-68.067-46.047-96-77l-1-2c.996.086 1.829-.248 2.5-1 2.33-3.822 4.83-7.488 7.5-11z"
            opacity=".998" />
          <path fill="#f8d889"
            d="M24.5 385.5c10.938 14.844 22.604 29.178 35 43v1l-18 27h-1c-10.452-11.107-20.452-22.441-30-34 4.294-12.559 8.961-24.893 14-37z" />
          <path fill="#faf098"
            d="M89.5 388.5c27.933 30.953 59.933 56.62 96 77a1419.2 1419.2 0 0 1-40 14c-27.991-17.644-52.991-38.977-75-64a200.28 200.28 0 0 1 19-27z"
            opacity=".996" />
          <path fill="#ffb25b"
            d="M416.5 405.5c-12.088 17.061-14.255 35.394-6.5 55 3.294 6.437 7.128 12.437 11.5 18-18.721 6.373-34.221 1.707-46.5-14-10.409-19.712-7.576-37.212 8.5-52.5 10.124-7.215 21.124-9.381 33-6.5z" />
          <path fill="#ffc98a"
            d="M416.5 405.5c18.752 5.141 28.752 17.474 30 37-.969 17.317-9.303 29.317-25 36-4.372-5.563-8.206-11.563-11.5-18-7.755-19.606-5.588-37.939 6.5-55z"
            opacity=".983" />
          <path fill="#6aa3dd"
            d="M68.5 414.5l2 1c22.009 25.023 47.009 46.356 75 64-6.302 3.102-12.969 5.436-20 7-23.453-17.099-45.452-36.099-66-57v-1c4.51-3.684 7.51-8.351 9-14z"
            opacity=".997" />
          <path fill="#398dd7"
            d="M10.5 422.5c9.548 11.559 19.548 22.893 30 34-1.543 4.757-3.376 9.424-5.5 14-.671.752-1.504 1.086-2.5 1a323.31 323.31 0 0 1-28-31 284.73 284.73 0 0 1 6-18z"
            opacity=".991" />
          <path fill="#faf098"
            d="M59.5 429.5c20.548 20.901 42.547 39.901 66 57-12.107 5.039-24.441 9.706-37 14a903.59 903.59 0 0 0-26-22.5c-7.203-7.035-14.203-14.202-21-21.5l18-27z"
            opacity=".994" />
          <path fill="#f9d989"
            d="M4.5 440.5a323.31 323.31 0 0 0 28 31l1 2a80.17 80.17 0 0 0-1.5 26c.975 3.26 2.142 6.427 3.5 9.5-.594.464-1.261.631-2 .5-17.868-5.867-29.201-17.867-34-36v-20c1.467-4.401 3.134-8.734 5-13z" />
          <path fill="#6ba3dd"
            d="M40.5 456.5h1c6.797 7.298 13.797 14.465 21 21.5a903.59 903.59 0 0 1 26 22.5c-5.933 2.2-11.933 4.2-18 6-12.605-10.6-24.939-21.6-37-33l-1-2c.996.086 1.829-.248 2.5-1 2.124-4.576 3.957-9.243 5.5-14z"
            opacity=".994" />
          <path fill="#f9f098"
            d="M33.5 473.5c12.061 11.4 24.395 22.4 37 33-4.266 1.866-8.599 3.533-13 5h-20l-4-2c.739.131 1.406-.036 2-.5-1.358-3.073-2.525-6.24-3.5-9.5a80.17 80.17 0 0 1 1.5-26z"
            opacity=".987" />
        </svg>
        <div class="fw-bold text-primary fs-5">{{'congratulation'|translate}}</div>
      </div>
      <img *ngIf="statusCode === 7" class="rd-level-img mb-2" src="../../../../../assets/image/shield.png"  alt="image">
      <img *ngIf="statusCode === 10" class="rd-level-img mb-2" src="../../../../../assets/image/closed.png" alt="image">
      <img *ngIf="statusCode === 17" class="rd-level-img mb-2" src="../../../../../assets/image/shield.png" alt="image">
      <img *ngIf="![0, 2, 7, 10, 17].includes(statusCode)" class="rd-level-img mb-2"
        src="../../../../../assets/image/warning.gif" alt="image">
    </div>
    <div class="row text-center">
      <div class="col-md-12">
        <div>
          <p class="mb-1" [innerHTML]="surveyMessage"></p>
        </div>
      </div>
    </div>
    <div class="row text-center">
      <div class="col-md-12">
        <div class="mt-3 text-center">
          <div class="text-center mx-2">
            <p>{{'pleaseWait'|translate}}</p>
          </div>
          <div class="loader">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>