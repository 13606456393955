import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormControlName, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router'
import { BaseService } from 'src/app/base/service/base.service';
import { LoginRequest } from '../../models/LoginRequest';
import { userApi } from 'src/app/base/model/constants/ApiEndPoints';
import { PanelGuId, RecaptchaSiteKey } from 'src/app/base/model/constants/AppConstants';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ReCaptcha2Component } from 'ngx-captcha';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

  constructor(private router: Router,
    private fb: FormBuilder,
    private _baseService: BaseService,
    private toastr: ToastrService,
    private deviceDetector: DeviceDetectorService,
    private translate:TranslateService,
    private userService: UserService,
    private cookieService:CookieService) { }
  length: any;
  required = false;
  validPassword = false;
  show = false;
  showMessege = false;
  errorMessage = false;
  loginForm: FormGroup;
  forgetPasswordForm: FormGroup;
  panelistId;
  showResponseMessage;
  showErrorMessage = true;
  showSuccessMessage = true;
  responseLoader = false;
  showSuccessResponse;
  subject = new Subject;
  recaptchaSiteKey = RecaptchaSiteKey.siteKey;
  showCaptchaMessage;
  getEyeIconId = document.getElementById('passwordEye');
  getCulture;
  isEmailVerified:boolean;
  isShowLoader:boolean;
  @ViewChild(ReCaptcha2Component, { static: false }) captchaComponent: ReCaptcha2Component;
  /*@HostListener('window:scroll', ['$event'])*/

  ngOnInit() {

    // login form 
    this.loginForm = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
      // recaptcha: ['', Validators.required]
    });


    // Forget password form
    this.forgetPasswordForm = this.fb.group({
      forgotEmail: new FormControl('', [Validators.required, Validators.email])
    })

    if (localStorage.getItem('access_token')) {
      this.router.navigate(['login/body/dashboard'])
    }

    this.userService.countryCultureSource.subscribe(country => {
      this.getCulture = country;      
    })
  }

  get email() {
    return this.loginForm.get('email');
  }
  get password() {
    return this.loginForm.get('password');
  }
  get forgotEmail() {
    return this.forgetPasswordForm.get('forgotEmail');
  }
  // Method for login user
  userLogin() {
    this.isShowLoader = true;
    this.markFormGroupTouched(this.loginForm);
    const email = this.loginForm.value.email;
    const password = this.loginForm.value.password;
    const recaptcha = this.loginForm.value.recaptcha;

    const body = {
      email: email,
      password: password,
      panelGuid: PanelGuId.OpinionEdge,

    }
    const reCaptchaTokenBody = {
      token: recaptcha
    }

    if (this.loginForm.valid) {
      // this._baseService.post(userApi.verifyReCaptcha, reCaptchaTokenBody).then((res)=>{
        this._baseService.post(userApi.login, body).then((res: any) => {
          this.isShowLoader = false;
          // if (res?.data[0]?.isEmailVerified !== true) {
          //   this.isEmailVerified = true;
          //   return;
          // }
          this.panelistId = res?.data[0]?.panelistId;
          const profileImage = res.data[0].panelistImage;
          localStorage.setItem('access_token', res.token.access.token)
          localStorage.setItem('refresh_token', res.token.refresh.token)
          localStorage.setItem('countryId', res.data[0].countryId)
          localStorage.setItem('languageId', res.data[0].languageId)
          localStorage.setItem('url_refer', res.data[0].referralCode)
          localStorage.setItem('profileImage', profileImage),
          localStorage.setItem('uEmail',res.data[0].email)
            localStorage.setItem('cS', res.data[0].currency)
          sessionStorage.setItem('iEV', res.data[0]?.isEmailVerified)
          this.router.navigate(['login/body/dashboard']);
        }).catch((err) => {
          this.isShowLoader = false;
          this.showMessege = true;
          this.translate.get(err.statusCode).subscribe((translate) => {
            this.toastr.error(translate)
          })
          // this.loginForm.get('recaptcha').reset()
          // this.reloadCaptcha();
        })
      // }).catch((error) => {
      //   console.log(error);
      // });
    }
  }

  sendReferCode() {

  }

  // Methor for show/hide password
  openPassword() {
    this.show = !this.show;
  }

  // Method for forgot password
  onForgotPassword() {
    const body = {
      email: this.forgetPasswordForm.value.forgotEmail,
      panelGuid: PanelGuId.OpinionEdge
    }
    this._baseService.post(userApi.forgotPassword, body).then((res: any) => {
      this.responseLoader = true;
      setTimeout(() => {
        this.responseLoader = false;
      }, 1000);
      this.showSuccessMessage = true;
      this.translate.get('forgotPasswordMail').subscribe((translate)=>{
        this.toastr.success(translate,'',{
          toastClass: 'success-toast',
          timeOut: 3000,
        })
      })
    }).catch((error) => {
      this.translate.get('noUserFound').subscribe((translate)=>{
        this.toastr.error(translate)
      })
      this.showSuccessMessage = false;
      setTimeout(() => {
        this.showErrorMessage = false;
      }, 5000);
    })
    this.forgetPasswordForm.reset()
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    })
  }

  successCaptcha(event){
  //  console.log('succeessFullCaptcha >>>', event);
  }

  reloadCaptcha(): void {
    this.captchaComponent.reloadCaptcha();
  }
}
