import { Component, OnInit } from '@angular/core';
import { BaseService } from 'src/app/base/service/base.service';
import { userApi } from 'src/app/base/model/constants/ApiEndPoints';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../services/user.service';
import { CustomToastrServiceService } from 'src/app/modules/base/services/custom-toastr-service.service';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { take } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {
  constructor(
    private _baseService: BaseService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private userService: UserService,
    private customToastrService:CustomToastrServiceService,
    private translateService:TranslateService,
    private translate: TranslateService,
    private deviceDetector:DeviceDetectorService
  ) { 
    this.epicFunction()
  }

  completeSurvey = 0;
  userPoint;
  getPanelistId;
  availablePoints = 0;
  totalPoint = 0;
  surveyData;
  referHistory;
  sentReferral = 0;
  showDataMessage = false;
  errorMessage: string;
  getIpAddress: string = "";
  emptyMessage;
  referStatus;
  isMobile;
  isTablet;
  isDesktop
  isDisabled: boolean = false;
  isLoader:boolean;
  isEmailVerified:boolean = false;
  userEmail;
  isEmailCheck:boolean;
  currentCountryCode:string;
  userCountryCode:string;
  isSecurityCheck:any;
  isVPN:boolean;
  ngOnInit() {
    this.isLoader = true;
    this.userService.sendUserProfile.subscribe((res:any)=>{
      this.isLoader = false;
      this.userEmail = res.email
      this.isEmailVerified = res.isEmailVerified;
      setTimeout(() => {
        this.isEmailCheck = true;
      }, 0);
    })
    
    // Method for Get IP Address
      this._baseService.get(userApi.ipAddress).then((res: any) => {
        this.getIpAddress = res.IP;
      })

    this.getUserSecurityCheck();

    // Method for getSurveyHistory
    this.getPanelistId = localStorage.getItem('panelistId')
    this._baseService.get(userApi.getSurveyHistoryApi).then((res: any) => {
      if (res) {
      this.completeSurvey = res.surveyComplete ? res.surveyComplete : 0;
      this.getReferralHistory();
      this.getPoints();
      }
    }).catch((err) => {
      this.errorMessage = err.message;
    })

    // Method for show custom toastr
    const isEmailVerified = JSON.parse(sessionStorage.getItem('iEV'))
    if (isEmailVerified == false) {
      this.customToastrService.showCustomToastr('', '')
    }
    // console.error = function () { }
  }

  getUserSecurityCheck(){
    this.userService.getSurveyCheckAPI().then((res:any)=>{
      const userCurrentDetails = res
      this.currentCountryCode = userCurrentDetails?.param1;
      this.isSecurityCheck = userCurrentDetails?.param4.toLowerCase() === 'true';
      this.isVPN = userCurrentDetails?.param2;
    }).catch((err)=>{
      this.isLoader = false;
      this.showDataMessage = true;
    })
  }

  // Method for getReferralHistory
  getReferralHistory() {
    this._baseService.get(userApi.referaFriend).then((res: any) => {
      this.referHistory = res.data ? res.data : [res];
      this.sentReferral = res.data ? res.data.length : [res].length;
      if (res.data.length === 0) {
        this.translateService.get('noReferral').subscribe((data: any) => {
          this.emptyMessage = data;
        })
      }
    }).catch((err) => {
      this.errorMessage = err.message;
    })
  }

  // Method for getSurveyForUser
  getSurveyForUser(surveyFor) {
    this.isLoader = true;
    this._baseService.get(`${userApi.getSurveyForUser}?surveyOption=${surveyFor}`).then((result: any) => {
      this.isLoader = false;
      const surveyData = result.data ? result.data : [result];
      this.surveyData = surveyData.slice(0, 10);
      this.surveyData.forEach(element => {
        element.isButtonClicked = false;
        this.checkDeviceType(element)
      });
      if (this.surveyData.length == 0) {
        this.showDataMessage = true;
      }
    }).catch((err) => {
      this.isLoader = false;
      this.showDataMessage = true;
      this.errorMessage = err.message;
    })
  }

  // Method for Get Points
  getPoints() {
    this._baseService.get(userApi.getPanelistPoints).then((res: any) => {
      this.userPoint = res;
      this.totalPoint = this.userPoint.totalPoints;
      this.availablePoints = this.userPoint.availablePoints;
      const userCountryCode = res.countryCode;
      this.surveySecurityCheck(userCountryCode);
      this.userService.sendPoints.next(this.totalPoint);
      this.userService.sendCountryCode.next(res?.countryCode)
      this.userService.sendUserLevel.next(res?.levelName);
      this.userService.sendUserminPoint.next(res?.minPoint);
      this.userService.callUserAPI.next(true)
    }).catch((err) => {
      this.errorMessage = err.message;
    })
  }

  surveySecurityCheck(userCountryCode) {
    if (this.isSecurityCheck) {
      if (this.currentCountryCode === userCountryCode && !this.isVPN) {
        this.getSurveyForUser('1');
      } else {
        this.showDataMessage = true;
      }
    } else {
      this.getSurveyForUser('1');
    }
  }

  // Method for take survey
  getSurvey(surveyId: number, clientUrl: any, item:any) {
    item.isButtonClicked = true;
    this.isDisabled = true;
    const panelistId = localStorage.getItem('panelistId');
    const surveyAlreadyExists = this.translateService.instant('alreadyParticipateSurvey')
    const arr = this.getIpAddress
    const body = {
      panelistId: Number(panelistId),
      surveyId: surveyId,
      ipAddress: arr,
      surveyFrom: 3
    }
    this._baseService.post(userApi.creatUserSurveyParticipation, body).then((res: any) => {
      localStorage.setItem('my_token', res.token)
      if (res.userStatus === 1) {
        this._baseService.get(userApi.getSurveyLinkWithQualification + `?panelistId=${panelistId}&surveyId=${surveyId}`).then((resLink: any) => {
          const getToken = localStorage.getItem('my_token')
          if (resLink.includes('{tid}')) {
            const redirectUrl = resLink.replace('{tid}', getToken).replace('{pid}', panelistId)
            this._baseService.put(userApi.updateUserSurveyParticipation + `?token=${getToken}`, {}).then((updateRes: any) => {
              if (updateRes.userStatus === 8) {
                window.location.href = redirectUrl;
                return
              }
              if (updateRes.userStatus === 7) {
                this.router.navigate([`/callback-survey/${7}/${getToken}`])
                return;
              }
              if (updateRes.userStatus === 10) {
                this.router.navigate([`/callback-survey/${10}/${getToken}`])
                return;
              }
              if (updateRes.userStatus === 17) {
                this.router.navigate([`/callback-survey/${17}/${getToken}`])
                return;
              }
              this.router.navigate([`/callback-survey/${updateRes.userStatus}/${getToken}`])
            })
          } else {
            this.toastr.error('Invalid client Url')
            const getToken = localStorage.getItem('my_token')
            this.router.navigate([`/callback-survey/${11}/${getToken}`])
            this._baseService.put(userApi.updateUserSurveyParticipation + `?token=${getToken}`, {}).then((updateRes: any) => {
              console.log('Invalid client Url', updateRes);
            })
          }
        })
      }else{
        this.isDisabled = false;
        item.isButtonClicked = false;
        const getToken = localStorage.getItem('my_token')
        this.router.navigate([`/callback-survey/${res.userStatus}/${getToken}`])
      }
    }).catch((err)=>{
      this.isDisabled = false;
      item.isButtonClicked = false;
      this.router.navigate([`/callback-survey/${err.userStatus}/${err.token}`])
    })
  }

  epicFunction() {
    this.isMobile = this.deviceDetector.isMobile();
    this.isTablet = this.deviceDetector.isTablet();
    this.isDesktop = this.deviceDetector.isDesktop();
  }

  checkDeviceType(item): any {
    const isUserDesktop = this.isDesktop;
    const isUserMobile = this.isMobile;
    const isUserTablet = this.isTablet;
    item.isDisabled = true;
    switch (true) {
      case item.isDesktop && isUserDesktop:
      case item.isMobile && isUserMobile:
      case item.isIpad && isUserTablet:
      case (item.isDesktop && item.isIpad && item.isMobile) &&
           (isUserDesktop || isUserTablet || isUserMobile):
        item.isDisabled = false;
        break;
      default:
        item.isDisabled = true;
        break;
    }
  }

  getTooltipText(item: any): string {
    const surveyDeviceArray = [];
  
    if (item.isDesktop) {
      surveyDeviceArray.push(this.translate.instant('desktop'));
    }
    if (item.isMobile) {
      surveyDeviceArray.push(this.translate.instant('mobile'));
    }
    if (item.isIpad) {
      surveyDeviceArray.push(this.translate.instant('tablet'));
    }
  
    switch (surveyDeviceArray.length) {
      case  1:
      case  2:
        const devices = surveyDeviceArray.join(' ' + this.translate.instant('and') + ' ');
        const showMessage = this.translate.instant('availableOn') + ' ' + devices
        return showMessage;
      case  3:
        const allDeviceMes = this.translate.instant('availableOn') + ' ' + this.translate.instant('allDevices')
        return allDeviceMes;
      default:
        return this.translate.instant('noSupportedDevice');
    }
  }
  

  /*cardClickTabs*/

  showPoints = false;
  showCompleteSurvey = false;
  showSentReferrals = false;
  showAvailablePoint = false;
  getpopUp() {
    this.showPoints = !this.showPoints;
    this.showCompleteSurvey = false;
    this.showSentReferrals = false;
    this.showAvailablePoint = false;
  }
  getCompleteSurvey() {
    this.showCompleteSurvey = !this.showCompleteSurvey;
    this.showPoints = false;
    this.showSentReferrals = false;
    this.showAvailablePoint = false;
  }
  getSentReferrals() {
    this.showSentReferrals = !this.showSentReferrals;
    this.showPoints = false;
    this.showCompleteSurvey = false;
    this.showAvailablePoint = false;
  }
  getAvailablePoint() {
    this.showAvailablePoint = !this.showAvailablePoint;
    this.showPoints = false;
    this.showCompleteSurvey = false;
    this.showSentReferrals = false;
  }

  sendEmailButton(){
    this.userService.verifyEmail(this.userEmail).then((res: any) => {
      this.translate.get('sentVerificationEmail').subscribe((translate) => {
        this.toastr.success(translate, '', {
          toastClass: 'success-toast',
          timeOut: 3000,
        })
      })
    }).catch((res) => {
      // console.log(res);
      this.toastr.error(res.error.message)
    })
  }

}
