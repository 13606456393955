<div class="container-fluid">
  <div class="privacy-page">
    <div class="terms-box fs-6 text-secondary">
      <span class="m-0">
        <h3 class="privacy-heading">
          {{'termsTitle1'|translate}}
        </h3>
      </span>
      <p class=" m-0">
        {{'agreementTitle1'|translate}}
      </p>
      <br />
      <p class=" m-0">
        {{'agreementTitle2'|translate}}
      </p>
      <br />
      <p class=" m-0">
        {{'agreementTitle3'|translate}}
      </p>

      <span class="m-0">
        <h6 class=" privacy-heading">
          {{'termsTitle2'|translate}}
        </h6>
      </span>
      <p class=" m-0">
        {{'modificationTitle1'|translate}}
      </p>
      <br />
      <p class=" m-0">
        {{'modificationTitle2'|translate}}
      </p>
      <span class="m-0">
        <h6 class="privacy-heading"> {{'termsTitle3'|translate}}</h6>
      </span>
      <p class=" m-0">
        {{'linksTitle1'|translate}}
      </p>
      <span class="m-0">
        <h6 class="privacy-heading">
          {{'termsTitle4'|translate}}
        </h6>
      </span>
      <p class=" m-0">
        {{'prohibitedTitle1'|translate}}
      </p>
      <span class="m-0">
        <h6 class="privacy-heading"> {{'termsTitle5'|translate}}</h6>
      </span>
      <p class=" m=0">
        {{'communicationTitle1'|translate}}
      </p>
      <p class=" m=0">
        {{'communicationTitle2'|translate}}
      </p>
      <p class=" m=0">
        {{'communicationTitle3'|translate}}
      </p>
      <p class=" m=0">
        {{'communicationTitle4'|translate}}
      </p>
      <p class=" m=0">
        {{'communicationTitle5'|translate}}
      </p>
      <p class=" m=0">
        {{'communicationTitle6'|translate}}
      </p>
      <p class=" m=0">
        {{'communicationTitle7'|translate}}
      </p>

      <span class="m-0">
        <h6 class="privacy-heading">{{'termsTitle6'|translate}}</h6>
      </span>
      <p class=" m-0">
        {{'confidentialityTitle1'|translate}}
      </p><br />
      <p class=" m=0">
        {{'confidentialityTitle2'|translate}}
      </p>
      <span class="m-0">
        <h6 class="privacy-heading">
          {{'termsTitle7'|translate}}
        </h6>
      </span>
      <p class=" m-0">
        {{'disclaimerTitle1'|translate}}
      </p>
      <p class=" m=0">
        {{'disclaimerTitle2'|translate}}
      </p>
      <p class=" m=0">
        {{'disclaimerTitle3'|translate}}
      </p>
      <p class=" m=0">
        {{'disclaimerTitle4'|translate}}
      </p>
      <p class=" m=0">
        {{'disclaimerTitle5'|translate}}
      </p>
      <p class=" m=0">
        {{'disclaimerTitle6'|translate}}
      </p>

      <span class="m-0">
        <h6 class="privacy-heading"> {{'termsTitle8'|translate}}</h6>
      </span>
      <p class=" m-0">
        {{'outreachTitle1'|translate}}
      </p>

      <span class="m-0">
        <h6 class="privacy-heading">
          {{'termsTitle9'|translate}}
        </h6>
      </span>
      <p class=" m-0">
        {{'intellectualTitle1'|translate}}
      </p>
      <p class=" m=0">
        {{'intellectualTitle2'|translate}}
      </p>
      <span class="m-0">
        <h6 class="privacy-heading">
          {{'termsTitle10'|translate}}
        </h6>
      </span>
      <p class=" m-0">
        {{'restrictionTitle1'|translate}}
      </p>
      <p class=" m-0">
        {{'restrictionTitle2'|translate}}
      </p>
      <p class=" m=0" [innerHTML]="'restrictionTitle3' | translate | newLine"></p>
      <p class=" m=0">
        {{'restrictionTitle4'|translate}}
      </p>
      <span class="m-0">
        <h6 class="privacy-heading">
          {{'termsTitle11'|translate}}
        </h6>
      </span>
      <p class=" m-0">
        {{'ProtectionTitle1'|translate}}
      </p>
      <p class=" m=0">
        {{'ProtectionTitle2'|translate}}
      </p>
      <p class=" m=0">
        {{'ProtectionTitle3'|translate}}
      </p>
      <span class="m-0">
        <h6 class="privacy-heading">
          {{'termsTitle12'|translate}}
        </h6>
      </span>
      <p class=" m-0">
        {{'disclaimerTitle'|translate}}
      </p>
    </div>
  </div>
</div>