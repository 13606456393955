<div class="container-fluid">

  <header class="sticky-top">
    <nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light border-bottom box-shadow header">
      <div class="container-fluid">
        <a class="navbar-brand">
          <img class="mx-1 logo-landingpage" src="../assets/icons/dashboard-logo-icon.png" />
        </a>
      </div>
      <hr />
    </nav>
  </header>

  <div class="center-div" *ngIf="isShowLoader">
    <div class="loader">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
  <div class="center-div pt-4 mx-width-700" *ngIf="isShowForm">
    <div class="unverified-email">
      <h3 class="fw-bold">{{'createNewPassword'|translate}}</h3>
      <p class="mt-3">{{'alertMessageForgotPassword'|translate}}
      </p>

      <div class="mt-3 d-flex justify-content-center">
        <div class="w-75 forgot-parent-div">
          <form [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPassword()" autocomplete="off">
            <div class="mb-3 txt-group">
              <div
                [ngClass]="forgotPasswordForm.get('password')?.invalid && forgotPasswordForm.get('password')?.touched ? 'invalid-input input-group':'input-group' ">
                <input 
                type="password"
                (focus)="showCriteria = true" 
                (blur)="showCriteria = false"
                (input)="checkPasswordStrength($event)" 
                [type]="!showPassword ? 'password' : 'text'" 
                formControlName="password"
                id="txtPassword" 
                [placeholder]="'password'|translate" 
                class="form-control revalidation-password"
                >
                <span class="p-2 icon-bottum">
                  <span class="cursor-pointer" (click)="openShowPassword()" *ngIf="!showPassword"><i
                      class="fa-regular fa-eye-slash"></i></span>
                  <span class="cursor-pointer" (click)="openShowPassword()" *ngIf="showPassword"><i
                      class="fa-sharp fa-regular fa-eye"></i></span>
                </span>

                <div class="password-criteria text-start" *ngIf="showCriteria">
                  <ul class="p-0 fs-9 text-secondary mb-0">
                    <li [ngClass]="{'text-success success-password':passwordStrength?.hasLetter, 'text-danger error-password': !passwordStrength?.hasLetter}">{{'containLetter'| translate}}</li>
                    <li [ngClass]="{'text-success success-password': passwordStrength?.hasNumber, 'text-danger error-password': !passwordStrength?.hasNumber}">{{'containNumber'| translate}}</li>
                    <li [ngClass]="{'text-success success-password': passwordStrength?.hasSpecialChar, 'text-danger error-password': !passwordStrength?.hasSpecialChar}">{{'containCharacter'| translate}}</li>
                    <li [ngClass]="{'text-success success-password': passwordStrength?.hasMinLength, 'text-danger error-password': !passwordStrength?.hasMinLength}">{{'contain8Characters'| translate}}</li>
                    <li *ngIf="passwordStrength?.hasSpace" [ngClass]="{'text-success success-password': !passwordStrength?.hasSpace, 'text-danger error-password': passwordStrength?.hasSpace}">{{'noSpace'| translate}}</li>
                  </ul>
                </div>
              </div>
              <div class="fs-7 mx-1 text-secondary float-start mt-1 mb-3" *ngIf="forgotPasswordForm.get('password')?.invalid && forgotPasswordForm.get('password')?.touched">{{'must8Character'|translate}}</div>
            </div>
            <div class="mb-3 txt-group">
              <div [ngClass]="forgotPasswordForm.get('confirmPassword')?.invalid && forgotPasswordForm.get('confirmPassword')?.touched || forgotPasswordForm.hasError('passwordMismatch') ? 'invalid-input input-group':'input-group' ">
                <input type="password" [type]="!show ? 'password' : 'text'" formControlName="confirmPassword"
                  id="txtPassword" [placeholder]="'confirmPassword'|translate " class="form-control revalidation-password">
                <span class="p-2 icon-bottum">
                  <span class="cursor-pointer" (click)="openPassword()" *ngIf="!show"><i
                      class="fa-regular fa-eye-slash"></i></span>
                  <span class="cursor-pointer" (click)="openPassword()" *ngIf="show"><i
                      class="fa-sharp fa-regular fa-eye"></i></span>
                </span>
              </div>
              <div class="fs-7 mx-1 text-secondary float-start mt-1 mb-3" *ngIf="forgotPasswordForm.get('confirmPassword')?.invalid && forgotPasswordForm.get('confirmPassword')?.touched || forgotPasswordForm.hasError('passwordMismatch')">{{'bothPasswordMatch'|translate}}</div>
            </div>
            <button type="submit" class="btn btn-primary btn-sm form-control">{{'submit'|translate}}</button>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="center-div pt-4 mx-width-700" *ngIf="isShowSuccess">
   <div class="unverified-email">
    <div class="text-center text-primary mb-2">
      <img class="rd-level-img" src="../../../../../assets/image/check.png" alt="">
    </div>
    <div class="mt-4">{{'resetSuccessMessage'|translate}}</div>
    <p class="text-secondary mt-3 fw-bold">{{'successResetPassword'|translate}}</p>
    <p class="text-secondary fw-bold">{{'please'|translate}} <a class="text-primary redirectlogin"
        routerLink="/">{{'clickHere'|translate}}</a> {{'toLogIn'|translate}}</p>
   </div>
  </div>

  <div class="center-div mx-width-700">
    <div class="unverified-email" *ngIf="isShowError">
      <div class="text-center text-primary mb-2">
        <i class="fa-sharp fa-solid fa-triangle-exclamation fs-1"></i>
      </div>
      <div class="mt-4 text-center">{{'resetPasswordExpire'|translate}}</div>
      <div class="message-bottum">
        {{'errorPasswordMessage'|translate}} <br>
        {{'thankYou'|translate}}

      </div>
    </div>
  </div>
</div>