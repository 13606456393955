import { Component } from '@angular/core';
import { userApi } from 'src/app/base/model/constants/ApiEndPoints';
import { PanelGuId } from 'src/app/base/model/constants/AppConstants';
import { BaseService } from 'src/app/base/service/base.service';

@Component({
  selector: 'app-technology',
  templateUrl: './technology.component.html'
})
export class TechnologyComponent {
  constructor(private _baseService: BaseService) { }

  profileQuestion;

  ngOnInit() {


    const panelGuid = PanelGuId.OpinionEdge;
    const countryId = 2;
    const languageId = 1;
    const categoryId = 6;
    const panelistId = localStorage.getItem('panelistId')

    this._baseService.get(userApi.getProfileCatQsn + `?panelGuid=${panelGuid}&countryId=${countryId}&languageId=${languageId}&categoryId=${categoryId}&panelistId=${panelistId}`).then((res: any) => {
      console.log(res.data)
      this.profileQuestion = res.data
    })
  }
}
