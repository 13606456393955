import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BaseService } from 'src/app/base/service/base.service';
import { UserService } from '../../services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomToastrServiceService } from 'src/app/modules/base/services/custom-toastr-service.service';

@Component({
  selector: 'app-user-faqs',
  templateUrl: './user-faqs.component.html'
})
export class UserFaqsComponent {
  constructor(private _baseService: BaseService,
    private toastr: ToastrService,
    private userService: UserService,
    private translate: TranslateService,
    private customToastrService: CustomToastrServiceService
  ) { }

  ngOnInit() {
    this.userService.getuserTotalPoint();

    // method for show custom toastr 
    const isEmailVerified = JSON.parse(sessionStorage.getItem('iEV'))
    if (isEmailVerified == false) {
      this.customToastrService.showCustomToastr('', '')
    }

  }
}