import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { userApi } from 'src/app/base/model/constants/ApiEndPoints';
import { BaseService } from 'src/app/base/service/base.service';
import { UserService } from 'src/app/modules/users/services/user.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent {
  constructor(private _baseService: BaseService,
    private fb: FormBuilder,
    private Activeroute: ActivatedRoute,
    private router:Router,
    private userService: UserService
  ) { }
  show = false;
  showPassword = false;
  isShowForm = false;
  isShowError = false;
  isShowLoader = true;
  isShowSuccess = false;
  forgotPasswordForm: FormGroup;
  access_token;
  showMessage;
  showCriteria:boolean;
  passwordStrength:any;
  ngOnInit() {

    // Method for verify forgot password token
    this.access_token = this.Activeroute.snapshot.queryParamMap.get('token');
    this._baseService.post(userApi.verifyForgotPassword + `?token=${this.access_token}`, {}).then((res: any) => {
        this.isShowLoader = false;
        this.isShowForm = true;
        this.isShowSuccess = false;
        this.isShowError = false;
    }).catch((res: any) => {
      this.isShowLoader = false;
      this.isShowForm = false;
      this.isShowSuccess = false;
      this.isShowError = true;
    })
    this.forgotPasswordForm = this.fb.group({
      password: new FormControl('', [Validators.required,  Validators.pattern('^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+].{7,}'), Validators.minLength(8),  this.noSpaceValidator,]),
      confirmPassword: new FormControl('', Validators.required)
    }, { validator: this.passwordMatchValidator })
  }

  // Method for forgot password
  forgotPassword() {
    this.markFormGroupTouched(this.forgotPasswordForm);
    if (this.forgotPasswordForm.invalid) {
      return;
    }
    if (this.forgotPasswordForm.valid) {
      const body = {
        password: this.forgotPasswordForm.value.password
      }
      this._baseService.post(userApi.resetPassword + `?token=${this.access_token}`, body).then((res: any) => {
        this.isShowLoader = false;
        this.isShowForm = false;
        this.isShowSuccess = true;
        this.isShowError = false;
      }).catch((res: any) => {
        this.isShowLoader = false;
        this.isShowError = true;
        this.showMessage = res.error.message;
        this.isShowForm = false;
        this.isShowSuccess = false;
      })
    }
  }

  openPassword() {
    this.show = !this.show;
  }
  openShowPassword() {
    this.showPassword = !this.showPassword;
  }

  // Method for matchPassword 
  passwordMatchValidator(formGroup: FormGroup) {
    const password = formGroup.get('password').value;
    const confirmPassword = formGroup.get('confirmPassword').value;
    if (password === confirmPassword) {
      return null;

    } else {
      return { passwordMismatch: true };
    }
  }

  noSpaceValidator(control: AbstractControl): ValidationErrors | null {
    if (/\s/.test(control.value)) {
      return { noSpace: true };
    } else {
      return null;
    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    })
  }

  checkPasswordStrength(event: any) {
    const password = event.target.value;
      this.userService.checkPasswordStrength(password);
      this.passwordStrength = {
        hasLetter: this.userService.hasLetter,
        hasNumber: this.userService.hasNumber,
        hasSpecialChar: this.userService.hasSpecialChar,
        hasMinLength: this.userService.hasMinLength,
        hasSpace: this.userService.hasSpace
      };
    }
}
