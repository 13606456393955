import { Injectable } from '@angular/core';
import { LoggerService } from './logger.service';

import {
  HttpClient,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpRequest,
} from '@angular/common/http';
import { Configuration } from 'src/app/helpers/configuration';
import { AppInjector } from './app-injector.service';
import { HttpInterceptor } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class BaseService implements HttpInterceptor {
  genericError = 'Some error occured ';
  protected loggerSevice: LoggerService;
  protected _baseApiUrl: string;
  // private loginaccount: LoginAccount= new LoginAccount();
  public configuration = new Configuration();
  public requestOptions = {};
  //public current_user: LoginUser = new LoginUser();
  //public form_validators: ValidatorsModel = new ValidatorsModel();

  constructor(public http: HttpClient) {
    this.loggerSevice = AppInjector.getInjector().get(LoggerService);
    this._baseApiUrl = environment.baseUrl;
    let headers = new HttpHeaders();
    let authHeaders = headers.set(
      'Authorization',
      'Bearer ' + this.configuration.accessToken
    );
    this.requestOptions = {
      headers: authHeaders,
    };
  }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req)
  }

  get<T>(urlPath: string): Promise<T> {
    const url: string = this._baseApiUrl + urlPath;
    return new Promise<T>((resolve, reject) => {
      this.http
        .get<T>(url, this.requestOptions)
        .toPromise()
        .then((resp) => {
          const processedResponse = this.handleResponse<T>(resp);
          resolve(processedResponse);
        })
        .catch((err) => {
          const processedError = this.handleError(err);
          reject(processedError);
        })
    });
  }

  post<T>(urlPath: string, body: object): Promise<T> {
    const url: string = this._baseApiUrl + urlPath;
    return new Promise<T>((resolve, reject) => {
      this.http
        .post<T>(url, body, this.requestOptions)
        .toPromise()
        .then((resp) => {
          const processedResponse = this.handleResponse<T>(resp);
          resolve(processedResponse);
        })
        .catch((err) => {
          const processedError = this.handleError(err);
          reject(processedError);
        })
    });
  }

  put<T>(urlPath: string, body: object): Promise<T> {
    const url: string = this._baseApiUrl + urlPath;
    return new Promise<T>((resolve, reject) => {
      this.http
        .put<T>(url, body, this.requestOptions)
        .toPromise()
        .then((resp) => {
          const processedResponse = this.handleResponse<T>(resp);
          resolve(processedResponse);
        })
        .catch((err) => {
          const processedError = this.handleError(err);
          reject(processedError);
        })
    });
  }

  // delete<T>(urlPath: string): Promise<T> {
  //const url: string = this._baseApiUrl + urlPath;
  //   return new Promise<T>(async (resolve, reject) => {
  //     this.http
  //       .delete<T>(url, this.requestOptions)
  //       .toPromise()
  //       .then((resp) => {
  //         resolve(<T>resp);
  //       })
  //       .catch((err) => {
  //         reject(err);
  //       });
  //   });
  // }
  patch<T>(urlPath: string, body: object): Promise<T> {
    const url: string = this._baseApiUrl + urlPath;
    return new Promise<T>((resolve, reject) => {
      this.http
        .patch<T>(url, body, this.requestOptions)
        .toPromise()
        .then((resp) => {
          const processedResponse = this.handleResponse<T>(resp);
          resolve(processedResponse);
        })
        .catch((err) => {
          const processedError = this.handleError(err);
          reject(processedError);
        })
    });
    }

    private handleResponse<T>(resp: any): T {
      if (resp.success) {
        if (resp.data.length === 0 || resp.data.length > 1 || resp.token) {
          return resp as T;
        } else {
          return resp.data[0] as T;
        }
      } else {
        throw resp;
      }
    }

    private handleError(err: any) {
      if (err.status >= 400) {
        return err.error
      } else {
        const errMethod = err.data ? err.data : [err]
        if (errMethod.length > 0) {
          return errMethod[0]
        } else {
          return err;
        }
      }
    }
  }
