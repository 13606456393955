import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { timeout } from 'rxjs';
import { CustomToastComponent } from '../components/custom-toast/custom-toast.component';

@Injectable({
  providedIn: 'root'
})
export class CustomToastrServiceService {

  constructor(private toastr: ToastrService) { }


  showCustomToastr(message: string, title: string) {

    const toastrOptions: Partial<IndividualConfig> = {
      closeButton: true,
      tapToDismiss: false,
      timeOut: 2000,
    }



    this.toastr.show(null, null, {
      ...toastrOptions,
      extendedTimeOut: 0,
      toastComponent: CustomToastComponent,
      toastClass: 'ngx-toastr custom-toastr',
      positionClass: 'toast-top-right',
      disableTimeOut: true,
      enableHtml: true,
      progressBar: false,
    })
  }
}
