<div class="container-fluid">
    <div class="row fixed-topheader">
        <div class="row sticky-top">
          <div class="d-flex justify-content-between dashboard-mainboady">
            <div class="dashboard-heading">
              <h3>{{'contactUs'|translate}}</h3>
            </div>
            <div class="dashboard-headingicons">
              <app-header></app-header>
            </div>
          </div>
          <hr class="dashboardhr-line" />
        </div>
      </div>
      <div  class="row fixed-body">
        <div class="container-fluid ">
            <div class="row px-3">
              <h3 class="contactus-header"> {{'getInTouch'|translate}}</h3>
              <p class="contactus-summary">
                {{'contactTitle'|translate}}<br />
                {{'thankYou'|translate}}
                <br /><br />
                {{'teamOpinion'|translate}}
              </p>
              <div class="row">
                <div class="col-md-12">
                  <div class="banner-box">
                    <div class="row row-flex">
                      <!-- <div class="col-12 col-lg-6 user-contact-us">
                         <div>
                          <div class="mb-3 txt-group email">
                            <label class="form-label">
                              <span class="text-primary fs-6 mx-2"> <img src="../assets/icons/send image.png" /></span>
                              {{'emailId'|translate}}
                            </label>
                            <p>{{'contactEmail'|translate}}</p>
                          </div>
                          <div class="mb-3 email txt-group">
                            <label class="form-label">
                              <span class="text-primary fs-6 mx-2"> <i class="fa-solid fa-location-dot"></i></span>
                              {{'address'|translate}}
                            </label>
                            <p>{{'contactAddress'|translate}}</p>
                          </div>
                         </div>
                      </div> -->
                      <div  class="col-12 col-lg-12 user-contact-us">
                        <form [formGroup]="userSupportForm" (ngSubmit)="onSubmitrequest()">
                            <div>
                              <div class="row contact-textgroup">
                                <div class="col-md-6">
                                  <div class="mb-2 txt-group">
                                    <input type="email" formControlName="email" [placeholder]="'email'|translate" class="form-control text-secondary fs-6 user-support-email" [(ngModel)]="userEmail" [disabled]="userEmail" [class.disabled-input]="userEmail">
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="mb-2 txt-group">
                                    <input type="text" formControlName="subject" [placeholder]="'subject'|translate" class="form-control text-secondary fs-6">
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-12 col-md-12">
                                  <div class="txt-group">
                                    <label class="input-group mb-3 support-filediv" for="picFile">
                                      <span class="input-group-text">{{'chooseFile'|translate}}</span>
                                      <span class="form-control pic-file-span">{{ picFileName.length > 0 ? picFileName.join(', '):('noFile'|translate)}}</span>
                                    </label>
                                    <input type="file" formControlName="selectFile" id="picFile"  class="form-control text-secondary d-none" (change)="onFileSelected($event)" multiple>
                                  </div>
                                  <div class="row selected-files-div">
                                    <div *ngFor="let fileName of picFileName; let i = index" class="filePhoto">
                                      <div class="cancel-img" (click)="cancelSelectedPhoto(i)">
                                        <i class="fa-solid fa-circle-xmark"></i>
                                      </div>
                                      <ng-container *ngIf="selectedFile[i] && isImageType(selectedFile[i].file.type)">
                                        <img *ngIf="selectedFile[i]" [src]="selectedFile[i].dataUrl" [alt]="picFileName[i]">
                                    </ng-container>
                                    <ng-container *ngIf="selectedFile[i] && !isImageType(selectedFile[i].file.type)">
                                      <div class="unknown-filetype">
                                        <img src="../../../../../assets/image/Docs.png" alt="Docs">
                                      </div>
                                    </ng-container>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row txt-group px-3">         
                                  <textarea formControlName="textMessage" class="col-12 col-md-6 text-area text-secondary user-text-area w-100 fs-6" [placeholder]="'description'|translate"></textarea>           
                              </div>
                              <!-- <div class="row p-2">
                                <div class="px-2">
                                  <ngx-recaptcha2 [siteKey]="recaptchaSiteKey" [hl]="getCulture"
                                    formControlName="recaptcha" class="recaptcha">
                                  </ngx-recaptcha2>
                                </div>
                              </div> -->
                              <div class="row mt-2 px-2">
                                <div class="col-md-3">
                                  <button [disabled]="userSupportForm.invalid" type="submit" class="btn btn-primary fs-6">{{'submit'|translate}}</button>
                                </div>
                                <div class="col">
                                  <span class="text-secondary">{{ticketResponse}}</span>
                                </div>
                              </div>
                            </div>
                        </form>
                      </div>
                    </div>
                    
                  </div>
                </div>
            
              </div>
            </div>
            
          </div>
      </div>
</div>
