import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import  {FingerprintjsProAngularModule} from "@fingerprintjs/fingerprintjs-pro-angular";
import {
  ExtendedGetResult,
  FingerprintjsProAngularService,
  GetResult,
} from '@fingerprintjs/fingerprintjs-pro-angular'

@Component({
  selector: 'app-fingerprint-component',
  templateUrl: './fingerprint.component.html',

})
export class FingerPrintComponent {

  /*isValidUrl: boolean = false;
  surveyId: any = null;
  visitorId: any = null;
  RID: any = null;
  uid: any = null;*/

  showErrorMessage: boolean = false;
  errorMessage: string = "";
  urlParams: any;
  visitorId: any;
  extendedResult: any;
  constructor(private router: Router, private routerQuery: ActivatedRoute, private fingerprintService: FingerprintjsProAngularService) { }

   ngOnInit() {
    /* this.visitorId = this.routerQuery.snapshot.queryParamMap.get('vid') ?this.routerQuery.snapshot.queryParamMap.get('vid') : "";
     this.surveyId = this.routerQuery.snapshot.queryParamMap.get('sid');
     this.uid = this.routerQuery.snapshot.queryParamMap.get('uid');
     this.RID = this.routerQuery.snapshot.queryParamMap.get('RID');
     if (this.visitorId != "" && this.surveyId != "") {
      this.isValidUrl = true;
    } //{ "x-fpid": result.visitorId, "sid": this.surveyId, "vid": this.visitorId, "RID": this.RID, "uid": this.uid } }*/
    //if (this.isValidUrl) { 
      //this.getVisitorID();
    //}
    // console.log('sdjhasbkdjvh', this.visitorId);
    this.onIdentifyButtonClick();
  }

    async onIdentifyButtonClick(): Promise<void> {
      try {
        const data = await this.fingerprintService.getVisitorData();
        this.visitorId = data.visitorId;
        console.log('visitorId: ', this.visitorId);
        this.extendedResult = data;
      } catch (error) {
        console.log('Something went wrong:', error);
      }
    }
  
}
