<header>
  <div class="container-fluid">
    <!--Responsive toggle button -->
    <span class="toggaleButton">
      <a class="fs-5" data-bs-toggle="offcanvas" href="#togglebuttonScrolling" role="button"
        aria-controls="offcanvasExample">
        <i class="fa-solid fa-bars"></i>
      </a>
    </span>
    <div class="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1"
      id="togglebuttonScrolling" aria-labelledby="offcanvasExampleLabel">
      <app-sidenav></app-sidenav>
    </div>
    <!--Responsive toggle button end-->

    <!--LogOut-->
    <span title="LogOut" class="fs-fw-5" (click)="isLogout()">
      <i class="fa-solid fa-power-off"></i>
    </span> &nbsp;
    <!--logOut end-->

    <!--Notification-->
    <span class="dropdown">
      <span class="fs-fw-5"  title="Notification" data-bs-toggle="dropdown" aria-expanded="false">
        <i class="fa-solid fa-bell  position-relative"></i>
        <span class="position-absolute start-100 badge-fit translate-middle badge rounded-pill bg-danger" *ngIf="showCount">
          {{notificationCount}}
        </span>
      </span>
      <ul class="dropdown-menu">
        <div class="sticky-top">
          <li class="form-label">{{'notification'|translate}}</li>
        <hr class="mt-0">
        </div>
        <div class="dropdown-content">
          <div class="notificationbody" *ngFor="let item of getNotification">
            <div class="d-flex justify-content-between">
              <div> <small class="text-primary fs-7 fw-bold">{{item.notificationType|translate}}</small></div>
              <div *ngIf="!item.isRead">
                <span class="notificationDate">
                <i class="fa-sharp fa-solid fa-circle unreadtic"></i>
                <small class="text-primary mx-1">{{item.formattedDate}}</small>
              </span>
              </div>
              <div *ngIf="item.isRead">
                <span class="notificationDate">
                <i class="fa-sharp fa-solid fa-circle readtic"></i>
                <small class="text-primary mx-1">{{item.formattedDate}}</small>
              </span>
              </div>
            </div>
            <span class="cursor-pointer" (click)="onShowNotification(item,item.notificationId)">
              <small>
                {{item.notification|translate}}
              </small>
            </span>
          </div>
        </div>
        <div *ngIf="isShowMessage">
          <small class="mx-2">
           {{'emptyNotiMessage'|translate}}
          </small>
        </div>
      </ul>
    </span>
    <!--Notification end-->
  </div>
</header>