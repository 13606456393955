import { Component } from '@angular/core';

@Component({
  selector: 'app-food',
  templateUrl: './food.component.html'
})
export class FoodComponent {
  items: string[] = [
    ' you are eating vagitable food', 'what is your favourite food', 'what is your favourite shake'
  ]
}
