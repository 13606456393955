<div class="container-fluid">
    <div class="row fixed-topheader">
      <div class="row sticky-top">
        <div class="d-flex justify-content-between dashboard-mainboady">
          <div class="dashboard-heading">
            <h3>{{'leaderBoard'|translate}}</h3>
          </div>
          <div class="dashboard-headingicons">
            <app-header></app-header>
          </div>
        </div>
        <hr class="dashboardhr-line" />
      </div>
    </div>
    <div class="row fixed-body">
      <div class="col-md-8 leaderboard">
        <div class="row">
          <div class="col-md-4 leader-detail">
            <div class="leader-primary">1st</div>
            <img src="https://cdn.britannica.com/97/1597-004-05816F4E/Flag-India.jpg" class="leader-flag" />
            <div class="leader-name">Johnathan Doe</div>
            <div class="leader-primary">7864</div>
          </div>
          <div class="col-md-4 leader-detail">
            <div class="leader-primary">1st</div>
            <img src="https://cdn.britannica.com/25/4825-004-F1975B92/Flag-United-Kingdom.jpg" class="leader-flag" />
            <div class="leader-name">Johnathan Doe</div>
            <div class="leader-primary">7864</div>
          </div>
          <div class="col-md-4 leader-detail">
            <div class="leader-primary">1st</div>
            <img src="https://cdn.britannica.com/33/4833-004-828A9A84/Flag-United-States-of-America.jpg" class="leader-flag" />
            <div class="leader-name">Johnathan Doe</div>
            <div class="leader-primary">7864</div>
          </div>
        </div>
        <div class="row leaderboard-summary">
          <div class="card leader-sumarycard">
            <div class="row">
              <div class="col-md-8 d-flex align-center">
                <div class="leader-primary">1st</div>
                <img src="https://cdn.britannica.com/97/1597-004-05816F4E/Flag-India.jpg" class="leader-flag" />
                <div class="leader-primary">Ravi</div>
                <div class="leader-name">CJDSDV</div>
              </div>
              <div class="col-md-4">
                <div class="leader-primary2">7864</div>
              </div>
            </div>
          </div>
          <div class="card leader-sumarycard">
            <div class="row">
              <div class="col-md-8 d-flex align-center">
                <div class="leader-primary">1st</div>
                <img src="https://cdn.britannica.com/82/65482-050-E43AB312/Flag-detail-Germany-crest.jpg" class="leader-flag" />
                <div class="leader-primary">Ravi</div>
                <div class="leader-name">CJDSDV</div>
              </div>
              <div class="col-md-4">
                <div class="leader-primary2">7864</div>
              </div>
            </div>
          </div>
          <div class="card leader-sumarycard">
            <div class="row">
              <div class="col-md-8 d-flex align-center">
                <div class="leader-primary">1st</div>
                <img src="https://cdn.britannica.com/97/1597-004-05816F4E/Flag-India.jpg" class="leader-flag" />
                <div class="leader-primary">Ravi</div>
                <div class="leader-name">CJDSDV</div>
              </div>
              <div class="col-md-4">
                <div class="leader-primary2">7864</div>
              </div>
            </div>
          </div>
          <div class="card leader-sumarycard">
            <div class="row">
              <div class="col-md-8 d-flex align-center">
                <div class="leader-primary">1st</div>
                <img src="https://cdn.britannica.com/97/1597-004-05816F4E/Flag-India.jpg" class="leader-flag" />
                <div class="leader-primary">Ravi</div>
                <div class="leader-name">CJDSDV</div>
              </div>
              <div class="col-md-4">
                <div class="leader-primary2">7864</div>
              </div>
            </div>
          </div>
          <div class="card leader-sumarycard">
            <div class="row">
              <div class="col-md-8 d-flex align-center">
                <div class="leader-primary">1st</div>
                <img src="https://cdn.britannica.com/97/1597-004-05816F4E/Flag-India.jpg" class="leader-flag" />
                <div class="leader-primary">Ravi</div>
                <div class="leader-name">CJDSDV</div>
              </div>
              <div class="col-md-4">
                <div class="leader-primary2">7864</div>
              </div>
            </div>
          </div>
          <div class="card leader-sumarycard">
            <div class="row">
              <div class="col-md-8 d-flex align-center">
                <div class="leader-primary">1st</div>
                <img src="https://cdn.britannica.com/97/1597-004-05816F4E/Flag-India.jpg" class="leader-flag" />
                <div class="leader-primary">Ravi</div>
                <div class="leader-name">CJDSDV</div>
              </div>
              <div class="col-md-4">
                <div class="leader-primary2">7864</div>
              </div>
            </div>
          </div>
          <div class="card leader-sumarycard">
            <div class="row">
              <div class="col-md-8 d-flex align-center">
                <div class="leader-primary">1st</div>
                <img src="https://cdn.britannica.com/97/1597-004-05816F4E/Flag-India.jpg" class="leader-flag" />
                <div class="leader-primary">Ravi</div>
                <div class="leader-name">CJDSDV</div>
              </div>
              <div class="col-md-4">
                <div class="leader-primary2">7864</div>
              </div>
            </div>
          </div>
          <div class="card leader-sumarycard">
            <div class="row">
              <div class="col-md-8 d-flex align-center">
                <div class="leader-primary">1st</div>
                <img src="https://cdn.britannica.com/97/1597-004-05816F4E/Flag-India.jpg" class="leader-flag" />
                <div class="leader-primary">Ravi</div>
                <div class="leader-name">CJDSDV</div>
              </div>
              <div class="col-md-4">
                <div class="leader-primary2">7864</div>
              </div>
            </div>
          </div>
          <div class="card leader-sumarycard">
            <div class="row">
              <div class="col-md-8 d-flex align-center">
                <div class="leader-primary">1st</div>
                <img src="https://cdn.britannica.com/97/1597-004-05816F4E/Flag-India.jpg" class="leader-flag" />
                <div class="leader-primary">Ravi</div>
                <div class="leader-name">CJDSDV</div>
              </div>
              <div class="col-md-4">
                <div class="leader-primary2">7864</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  