import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html'
})
export class FAQSComponent implements OnInit {
  constructor(private translate: TranslateService, private router: Router) { }
  showPrivacy: boolean;
  showOverview: boolean = true;
  showAccount: boolean;
  showSurveys: boolean;
  showRewards: boolean;
  Questions;
  userNotLogedIn:boolean;
  ngOnInit() {

  if (this.router.url === '/faqs') {
    this.userNotLogedIn = true;
  }
    
    this.translate.onLangChange.subscribe(() => {
      this.loadQuestions('Overview');
    });
    this.loadQuestions('Overview')
  }
  isShowTab(button: string) {
    this.resetTabs();
    switch (button) {
      case 'overview':
        this.showOverview = true;
        this.loadQuestions('Overview');
        break;
      case 'account':
        this.showAccount = true;
        this.loadQuestions('Account');

        break;
      case 'surveys':
        this.showSurveys = true;
        this.loadQuestions('Surveys');
        break;
      case 'rewards':
        this.showRewards = true;
        this.loadQuestions('Rewards');
        break;
      case 'privacy':
        this.showPrivacy = true;
        this.loadQuestions('Privacy');
        break;
    }
  }

  resetTabs() {
    this.showOverview = false;
    this.showAccount = false;
    this.showSurveys = false;
    this.showRewards = false;
    this.showPrivacy = false;
  }

  loadQuestions(tab: string) {
    this.translate.get(tab).subscribe((res: any[]) => {
      this.Questions = res;
    });
  }
}
