import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FingerprintjsProAngularService } from '@fingerprintjs/fingerprintjs-pro-angular';
import { DeviceDetectorService } from 'ngx-device-detector';
import { userApi } from 'src/app/base/model/constants/ApiEndPoints';
import { BaseService } from 'src/app/base/service/base.service';
import { UserService } from 'src/app/modules/users/services/user.service';

@Component({
  selector: 'app-email-verify',
  templateUrl: './email-verify.component.html'
})
export class EmailVerifyComponent {
  constructor(private activeRouter: ActivatedRoute,
    private _baseService: BaseService,
    private router: Router,
    private deviceDetector: DeviceDetectorService,
    private fingerprintService: FingerprintjsProAngularService,
    private userService: UserService
  ) {
    this.epicFunction();
  }
  deviceInfo = null;
  isShowLoader = true;
  isShowVerified: boolean;
  isShowUnVerified = false;
  showMobileDevice: boolean;
  visitorId = localStorage.getItem('visitorId');
  getIpAddress;
  extendedResult;
  panelistId;
  showMobileMessage: boolean;
  isRedirectLoader:boolean;
  isDesktop;
  ngOnInit() {
    setTimeout(async () => {
    await this.onIdentifyButtonClick();
    }, 500);
  }

  epicFunction() {
    this.deviceInfo = this.deviceDetector.getDeviceInfo();
    this.isDesktop = this.deviceDetector.isDesktop();
    // console.log(this.deviceInfo);
  }

  // closeModal(): void {
  //   const myModal = document.getElementById('exampleModal');
  //   if (myModal) {
  //     myModal.classList.remove('show');
  //     myModal.style.display = 'none';
  //     myModal.removeEventListener('click', this.closeModal);
  //   }
  // }
  continueBrowser() {
    this.router.navigate(['/login'])
  }
  openWithApp() {
    // Redirect to your app
    window.location.href = 'https://play.google.com/store/apps/details?id=com.unipanel';
  }

  async onIdentifyButtonClick() {
    localStorage.removeItem('email_Link')
  await this.getVisitorId();
    this._baseService.get(userApi.ipAddress).then((res: any) => {
      this.getIpAddress = res.IP;
    })
    const getAccess_Token = this.activeRouter.snapshot.queryParamMap.get('token');
    const startTime = Date.now();
    this._baseService.post(userApi.verifyEmail + `?token=${encodeURIComponent(getAccess_Token)}&visitorId=${this.visitorId}`, {}).then((res: any) => {
      const endTime = Date.now();
      const responseTime = endTime - startTime;
      this.isShowLoader = false;
      this.isShowVerified = true;
      this.isShowUnVerified = false;
      this.isRedirectLoader = true;
      this.panelistId = res.data[0].panelistId;
      localStorage.setItem('access_token', res.token.access.token)
      localStorage.setItem('countryId', res.data[0].countryId)
      localStorage.setItem('languageId', res.data[0].languageId)
      localStorage.setItem('url_refer', res.data[0].referralCode)
      localStorage.setItem('profileImage', res.data[0].panelistImage),
      localStorage.setItem('uEmail',res.data[0].email)
        localStorage.setItem('cS',res.data[0].currency)
      sessionStorage.setItem('iEV', res.data[0]?.isEmailVerified);
      this.updateAffiliateTrafic(this.visitorId, this.getIpAddress, this.panelistId)
      if (responseTime > 2000) {
        const loggerBody = {
          log: `ExtLogger for verifyEmail and Request Token: ${encodeURIComponent(getAccess_Token)} , API response time exceeded 2 seconds for ${userApi.verifyEmail}, Response Time: ${responseTime} ms`
        };
        this._baseService.post(userApi.extLogger, loggerBody).then((extLoggerRes: any) => {
          console.log('extLogger: ', extLoggerRes);
        });
      }
      setTimeout(() => {
        this.isRedirectLoader = false;
        if (this.isDesktop) {
          // this.showMobileDevice = true;
          this.router.navigate(['login/body/dashboard'])
        } else {
          if(!localStorage.getItem('email_Link'))localStorage.setItem('email_Link', '0')
          this._baseService.get(userApi.getDynamicLink).then((response:any)=>{
            const dynamicLink = response.dynamicUrl;
            const replaceUrl = dynamicLink.replace('%3Dtoken', `=${encodeURIComponent(getAccess_Token)}`)
            window.location.href = replaceUrl;
            console.log(replaceUrl);
          }).catch((error)=>{})

        }
        // if (devicetype === 'android') {
        //   this.showMobileMessage = true;
        //   this.showMobileDevice = false;
        // } else if (devicetype === 'windows') {
        //   this.showMobileDevice = true;
        // } else if (devicetype === 'ios') {
        //   this.showMobileMessage = true;
        //   this.showMobileDevice = false;
        // }
      }, 1500);
    })
      .catch((res: any) => {
        this.isShowLoader = false;
        this.isShowVerified = false;
        this.isShowUnVerified = true;
      });
  }

  updateAffiliateTrafic(visitorId, ipAddress, panelistId) {
    this._baseService.post(userApi.getAndUpdateAffiliateTraffic + `?visitorId=${visitorId}&panelistId=${panelistId}&ipAddress=${ipAddress}`, {}).then((res: any) => {
    })
  }

 async getVisitorId(){
    this.visitorId = await this.userService.getVistorId();
  }
}
