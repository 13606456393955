import { Component, OnInit } from '@angular/core';
import { BaseService } from 'src/app/base/service/base.service';
import { userApi } from 'src/app/base/model/constants/ApiEndPoints';
import { CustomToastrServiceService } from 'src/app/modules/base/services/custom-toastr-service.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../services/user.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-refer-earn',
  templateUrl: './refer-earn.component.html'
})
export class ReferEarnComponent implements OnInit {
  constructor(private _baseService: BaseService,
    private customToastrService: CustomToastrServiceService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private userService:UserService,
    private translateService:TranslateService
  ) { }
  referHistory;
  totalReferPoint = 0;
  copiedMessage;
  showMessage = true;
  referLink: string;
  errorMessage: string;
  sendReferLinkForm: FormGroup;
  referStatus;
  emptyMessage;
  ngOnInit() {

    // Method for referLink
    const getReferCode = localStorage.getItem('url_refer')
    this.referLink = `${location.origin}/#/create-account/${getReferCode}`


    // Method for getReferralHistory from send email
    const panelistId = localStorage.getItem('panelistId')
    this._baseService.get(userApi.referaFriend).then((res: any) => {
       this.referHistory = res.data ? res.data :[res];
      if (this.referHistory.length === 0) {
        this.translateService.get('noReferral').subscribe((data: any) => {
          this.emptyMessage = data;
        })
      }
      this.userService.getuserTotalPoint();
    }).catch((err) => {
      this.errorMessage = err.message;
    })

    // method for show custom toastr 
    const isEmailVerified = JSON.parse(sessionStorage.getItem('iEV'))
    if (isEmailVerified == false) {
        this.customToastrService.showCustomToastr('', '')
      } 
      const emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // Method for send refer email Form
    this.sendReferLinkForm = this.fb.group({
      name: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required,Validators.email,Validators.pattern(emailPattern)])
    })
    // const firstName
  }

  // Method for copy to clipboard for refer Link 
  copyToClipboard(text: string): void {
    this.showMessage = false;
    setTimeout(() => {
      this.showMessage = true;
    }, 5000);
    const clipboard = navigator.clipboard;
    clipboard.writeText(text).then(() => {
      this.translateService.get('linkCopied').subscribe((translate)=>{
        this.copiedMessage = translate;
      })
    })
  }


  // Method for send refer email
  sendReferEmail() {
    const getName = this.sendReferLinkForm.value.name.split(' ')
    const firstName = getName[0];
    const lastName = getName[1] || ''
    const formData = {
      firstName: firstName,
      lastName: lastName,
      email: this.sendReferLinkForm.value.email
    }
    this._baseService.post(userApi.referaFriend, formData).then((res: any) => {
      this.translateService.get('referEmailSent').subscribe((translate:string)=>{
        this.toastr.success(translate, '', {
          toastClass: 'success-toast',
          timeOut: 3000,
        })
       })
      this._baseService.get(userApi.referaFriend).then((res: any) => {
        
        this.referHistory = res.data ? res.data :[res];
        if (this.referHistory.length === 0) {
          this.translateService.get('noRecordFound').subscribe((data: any) => {
            this.emptyMessage = data;
          })
        }else{
          this.emptyMessage = ''
        }
      }).catch((err) => {
        this.errorMessage = err.error.message;
      })
    }).catch(error => this.translateService.get('referEmailFailed').subscribe((translate: any) => {
      this.toastr.error(translate)
    }))
    this.sendReferLinkForm.reset();
  }

  // Method for Input text validation
  validateInputText(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.keyCode);
    const pattern = /[A-Za-z\s]/;
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

}
