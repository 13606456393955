import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Buffer } from 'buffer';
import { BaseService } from 'src/app/base/service/base.service';
import { userApi } from 'src/app/base/model/constants/ApiEndPoints';
@Component({
  selector: 'app-gdi',
  templateUrl: './gdi.component.html'
})
export class GdiComponent implements OnInit {
  isShowErrorMessage: boolean = true;
  constructor(
    private deviceDetector: DeviceDetectorService,
    private activateRoute: ActivatedRoute,
    private router: Router,
    private _baseService: BaseService
  ) {
    this.epicFunction();
  }
  isDesktop;
  ngOnInit() {
    this.activateRoute.queryParams.subscribe((params) => {
      const queryParamsKey = params['token'];
      const surveyUrl = `${location.origin}/#/survey-landing?token=${queryParamsKey}`;
      localStorage.setItem('bulk_token', queryParamsKey);
      localStorage.removeItem('email_Link')
      if (queryParamsKey) {
        if (this.isDesktop) {
          // redirect to web
          this.router.navigate(['/survey-landing'], { queryParams: { token: queryParamsKey } })
        } else {
          // redirect to phone url
          if(!localStorage.getItem('email_Link'))localStorage.setItem('email_Link', '1')
          this._baseService.get(userApi.getDynamicLink).then((response:any)=>{
            const encodeSurveyUrl = Buffer.from(`${surveyUrl}`, 'utf8').toString('base64')
            const dynamicLink = response.dynamicUrl;
            const replaceUrl = dynamicLink.replace('%3Dtoken', `=${encodeURIComponent(queryParamsKey)}`).replace('%3DSurveyUrl', `=${encodeSurveyUrl}`)
            window.location.href = replaceUrl;
          }).catch((error)=>{})
        }
      } else {
        console.log('Invalid url')
      }
    })
  }


  epicFunction() {
    this.isDesktop = this.deviceDetector.isDesktop();
  }

}
