import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html'
})
export class NavMenuComponent {
  isExpanded = false;
  constructor(private router: Router) {

  }
  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
  getJoinNow() {
    if (sessionStorage.getItem("referralCode") !== "undefined" && sessionStorage.getItem("referralCode") !== null) {
      this.router.navigate([`/create-account/${sessionStorage.getItem('referralCode')}`]);
      // localStorage.removeItem('_affiliateUrl');
    }
    else if (sessionStorage.getItem('_affiliateUrl') !== "undefined" && sessionStorage.getItem("_affiliateUrl") !== null) {
      this.router.navigate([`/create-account/${sessionStorage.getItem('_affiliateUrl')}`,]);
      // sessionStorage.removeItem('referralCode');
    } else if ((sessionStorage.getItem("referralCode") === 'undefined' || sessionStorage.getItem("referralCode") === null) || (sessionStorage.getItem("_affiliateUrl") === 'undefined' || sessionStorage.getItem("_affiliateUrl") === null)) {
      this.router.navigate(['/create-account']);
      // sessionStorage.removeItem('_affiliateUrl');
      // sessionStorage.removeItem('referralCode');
    }

  }
}
