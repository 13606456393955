<div class="container">
    <div class="accordion accordion-flush">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#divShopping1" aria-expanded="false" aria-controls="divShopping1">
            {{'questionMessage'|translate}}
          </button>
        </h2>
        <div id="divShopping1" class="accordion-collapse collapse">
          <div class="row questions" *ngFor="let item of items">
            <div class="form-check mb-3">
              <label class="form-check-label">
                <input class="form-check-input" type="checkbox" value="">
                {{item}}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="row">
    <div class="text-end">
      <button class="btn btn-primary btn-sm questionsubmit-btn">Submit</button>
    </div>
  </div>
  