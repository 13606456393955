<div class="container-fluid">
  <div class="row fixed-topheader z-index-unset">
    <div class="row sticky-top">
      <div class="d-flex justify-content-between dashboard-mainboady">
        <div class="dashboard-heading">
          <h3>{{'myAccount'|translate}}</h3>
        </div>
        <div class="dashboard-headingicons">
          <app-header></app-header>
        </div>
      </div>
      <hr class="dashboardhr-line" />
    </div>
  </div>

  <div class="row fixed-body personal-detailtab">
    <ul class="nav personal-detail-nav">
      <li class="nav-item m-0" [ngClass]="isActive? 'personaldetails':'user-profile'" (click)="selectButton('myProfile')">
        <a>{{'myProfile'|translate}}</a>
      </li>
      <li class="nav-item" [ngClass]="!isActivechange? 'user-profile':'personaldetails'" (click)="selectButton('chnagePassword')">
        <a>{{'changePassword'|translate}}</a>
      </li>
      <ng-container *ngIf="userProfile?.countryCode == 'US'">
        <li class="nav-item" [ngClass]="!isActiveW9? 'user-profile':'personaldetails'" (click)="selectButton('w9Form')">
          <a>{{'W-9Form'|translate}}</a>
        </li>
      </ng-container>
    </ul>
    <div class="row" id="myProfile" *ngIf="isActive">

      <div class="col-md-12">
        <div class="row">
          <div class="row">
            <div class="row choose-avatar mt-2">
              {{'chooseYourAvatar'|translate}}
            </div>
          </div>
          <div class="d-flex flex-wrap avatar-list px-0 mt-2">

            <div *ngFor="let item of findAvtar; let i = index">
              <img class="avtar-img" id="getAvtar" [class.avatar]="selectedAvatarIndex === i" (click)="updateAvatar(item,i)" src="{{item.img}}" alt="">
            </div>
          </div>
        </div>

        <div class="row">
          <div class="row">
            <div class="col-md-6 emailp-5">
              <div class="mb-2 txt-group"  [ngbTooltip]="'email'|translate">
                <div class="input-group revalidation-password py-1">
                  <span><div class="fs-6 mt-1 px-2 mx-1">{{userEmail}}</div></span>
                  <span [ngClass]="isVerified ?'input-group-text text-primary  py-1 mt-1':'input-group-text text-primary p-0'">
                      <i class="fa-solid  fa-badge-check fa-circle-check" *ngIf="isVerified"></i>
                      <button *ngIf="!isVerified" class="btn btn-primary btn-sm  fs-7 text-light z-0" (click)="verifyEmail()">
                        {{'verifyNow'|translate}}
                      </button>
                    </span>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-2 txt-group" [ngbTooltip]="'gender'|translate">
                <select class="form-select form-input px-1 revalidation-password" disabled>
                  <i class="fa-solid fa-lock"></i>
                  <option selected>{{userGender}}</option>
                  <option value="1">{{'male'|translate}}</option>
                  <option value="2">{{'female'|translate}}</option>
                </select>
              </div>
            </div>
            <div class="col-md-3">
              <div class="mb-2 txt-group" ngbTooltip="DOB">
                <div class="input-group">
                  <input type="text" [value]="userDob" placeholder="DOB"
                    class="form-control revalidation-password"  disabled>
                  <span class="input-group-text icon-bottum">
                    <i class="fa-solid fa-lock"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form [formGroup]="updateProfileForm" (ngSubmit)="onUpdate()">
          <div class="row">
             <div class="row">
              <div class="col-md-4">
                <div class="mb-2 txt-group" [ngbTooltip]="'firstName'|translate">
                  <div class="input-group ">
                    <input type="text" [value]="userProfile?.firstName" formControlName="firstName"
                      [placeholder]="'firstName'|translate" class="form-control revalidation-password"
                      (keypress)="validateInputText($event)">
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-2 txt-group" [ngbTooltip]="'lastName'|translate">
                  <div class="input-group ">
                    <input type="text" [value]="userProfile?.lastName" formControlName="lastName"
                      [placeholder]="'lastName'|translate" class="form-control revalidation-password"
                      (keypress)="validateInputText($event)">

                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-2 txt-group" [ngbTooltip]="'mobileNo'|translate">
                  <div class="input-group ">
                    <span class="input-group-text revalidation-password border px-2 text-dark">{{iSDCode}}</span>
                    <input type="text" [value]="userProfile?.mobile" formControlName="mobile"
                      [placeholder]="'mobileNo'|translate" class="form-control revalidation-password px-1"
                      (keypress)="validationMobile($event)">
                  </div>
                </div>
              </div>
             </div>
            <div class="row" formGroupName="address">
              <div>
                <div class="mb-2 txt-group" [ngbTooltip]="'address'|translate">
                  <input type="text" formControlName="address" [placeholder]="'address'|translate"
                    class="form-control revalidation-password">
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-2 txt-group" [ngbTooltip]="'city'|translate">
                  <input type="text" formControlName="city" [placeholder]="'city'|translate"
                    class="form-control revalidation-password" (keypress)="validateInputText($event)">
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-2 txt-group" [ngbTooltip]="'zipCode'|translate">
                  <input type="text" formControlName="zipCode" [placeholder]="'zipCode'|translate"
                    class="form-control revalidation-password"  (input)="onZipValidation($event)">
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-2 txt-group" [ngbTooltip]="'state'|translate">
                  <input type="text" formControlName="state" [placeholder]="'state'|translate"
                    class="form-control revalidation-password" (keypress)="validateInputText($event)">
                </div>
              </div>
              <div class="row fs-6 text-secondary mx-2">
                {{zipErrorMessage}}
              </div>
            </div>

          </div>
          <div class="row mx-2">
            <div class="d-flex p-0">
              <div class="delete-account">
                <button data-bs-toggle="modal" type="button" data-bs-target="#deleteAccount"
                  class="btn btn-primary btn-sm bg-primary">{{'deleteMyAccount'|translate}}</button>

                <!-- Modal for delete account -->
                <form [formGroup]="deleteAccountForm" (ngSubmit)="onDeleteAccount()">
                  <div class="modal fade" id="deleteAccount" aria-labelledby="delete" aria-hidden="true">
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h1 class="modal-title form-label" id="delete">{{'deleteAccount'|translate}}</h1>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <div [ngClass]="deleteAccountForm.get('reasontxt')?.invalid && deleteAccountForm.get('reasontxt')?.touched?'form-floating textAreaBox':'form-floating'">
                            <textarea class="form-control text-secondary" formControlName="reasontxt"
                              placeholder="Leave a comment here" id="floatingTextarea2"
                              style="height: 100px"></textarea>
                            <label class="form-label" for="floatingTextarea2">Your Explanation is entirely Optional</label>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <!-- <div>
                            <input formControlName="checkboxSelect" type="checkbox"
                            id="accountDeleteCheck" [ngClass]="deleteAccountForm.get('checkboxSelect')?.invalid && deleteAccountForm.get('checkboxSelect')?.touched?'form-check-input mx-2 textAreaBox':'form-check-input mx-2'">
                              <label class="fs-7 cursor-pointer" for="accountDeleteCheck">
                                {{'accountDeleteMessage'| translate}} <span class="text-danger">*</span>
                              </label>
                          </div> -->
                          <button type="submit" [disabled]="isDeleteBtnDisabled"
                          class="btn btn-primary btn-sm delete-account-btn">{{'deleteMyAccount'|translate}}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div>
                <button type="submit" class="btn btn-success btn-sm mx-2" [disabled]="updateProfileForm.invalid">{{'updateMyProfile'|translate}}</button>
              </div>
            </div>
          </div>
        </form>

      </div>
    </div>
    <div id="changePassword" class="row" *ngIf="isActivechange">
      <form [formGroup]="changePasswordForm" (ngSubmit)="onChangePassword()">
        <div class="col-md-12">
          <div class="row">
            <span class="profileimg-header fs-5">
              {{'changePassword'|translate}}
            </span>
          </div>
          <div class="row">
            <div class="col-md-4 px-0">
              <div class="mb-3 txt-group">
                <!-- <label class="form-label">{{'enterPassword_personalDetails'|translate}}</label> -->
                <div class="input-group">
                  <input formControlName="currentPassword" [type]="!show?'password':'text'"  class="form-control revalidation-password" [placeholder]="'enterPassword_personalDetails'|translate">
                  <span (click)="openPassword()" *ngIf="!show">
                    <i class="fa-regular fa-eye-slash"></i>
                  </span>
                  <span (click)="openPassword()" *ngIf="show">
                    <i class="fa-sharp fa-regular fa-eye"></i>
                  </span>
                </div>
              </div>
              <div class="mb-3 txt-group">
                <!-- <label class="form-label">{{'pleaseEnterPassword'|translate}}</label> -->
                <div [ngClass]="changePasswordForm.get('newPassword')?.invalid && changePasswordForm.get('newPassword')?.touched ? 'invalid-input input-group':'input-group' ">
                  <input 
                  (focus)="showCriteria = true" 
                  (blur)="showCriteria = false"
                  (input)="checkPasswordStrength($event.target)" 
                  formControlName="newPassword" 
                  [type]="!showNewPassword?'password':'text'" 
                  class="form-control revalidation-password" 
                  [placeholder]="'pleaseEnterPassword'|translate"
                  >
                  <span (click)="openNewPassword()" *ngIf="!showNewPassword">
                    <i class="fa-regular fa-eye-slash"></i>
                  </span>
                  <span (click)="openNewPassword()" *ngIf="showNewPassword">
                    <i class="fa-sharp fa-regular fa-eye"></i>
                  </span>
                  <div class="password-criteria" *ngIf="showCriteria">
                    <ul class="p-0 fs-9 text-secondary mb-0">
                      <li [ngClass]="{'text-success success-password':passwordStrength?.hasLetter, 'text-danger error-password': !passwordStrength?.hasLetter}">{{'containLetter'| translate}}</li>
                      <li [ngClass]="{'text-success success-password': passwordStrength?.hasNumber, 'text-danger error-password': !passwordStrength?.hasNumber}">{{'containNumber'| translate}}</li>
                      <li [ngClass]="{'text-success success-password': passwordStrength?.hasSpecialChar, 'text-danger error-password': !passwordStrength?.hasSpecialChar}">{{'containCharacter'| translate}}</li>
                      <li [ngClass]="{'text-success success-password': passwordStrength?.hasMinLength, 'text-danger error-password': !passwordStrength?.hasMinLength}">{{'contain8Characters'| translate}}</li>
                      <li *ngIf="passwordStrength?.hasSpace" [ngClass]="{'text-success success-password': !passwordStrength?.hasSpace, 'text-danger error-password': passwordStrength?.hasSpace}">{{'noSpace'| translate}}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="mb-3 txt-group">
                <!-- <label class="form-label">{{'confirmNewPassword'|translate}}</label> -->
                <div [ngClass]="changePasswordForm.get('confirmPassword')?.invalid && changePasswordForm.get('confirmPassword')?.touched || changePasswordForm.hasError('passwordMismatch') ? 'invalid-input input-group':'input-group' ">
                  <input [type]="!showConfirmPassword?'password':'text'" (focus)="showConfirm = true" (blur)="showConfirm = false"
                    class="form-control revalidation-password" [placeholder]="'confirmNewPassword'|translate" formControlName="confirmPassword">
                  <span (click)="openConfirmPassword()" *ngIf="!showConfirmPassword">
                    <i class="fa-regular fa-eye-slash"></i>
                  </span>
                  <span (click)="openConfirmPassword()" *ngIf="showConfirmPassword">
                    <i class="fa-sharp fa-regular fa-eye"></i>
                  </span>
                </div>
                <div class="password-mismatch" *ngIf="showConfirm && changePasswordForm.hasError('passwordMismatch')">
                  <ul class="fs-7 text-secondary mb-0 px-2">
                    <li [ngClass]="{'text-success success-password':!changePasswordForm.hasError('passwordMismatch'), 'text-danger error-password': changePasswordForm.hasError('passwordMismatch')}">{{'confirmPasswordMessage'|translate}}</li>
                  </ul>
                </div>
              </div>
              <small class="text-secondary  px-2 text-end">
                {{showMessage}}
              </small>
              <div class="mb-3 mt-3 px-2">
                <button *ngIf="!isLoading" type="submit" class="btn btn-primary btn-sm" [disabled]="changePasswordForm.invalid">{{'changePassword'|translate}}</button>
                <button class="btn btn-primary btn-sm" type="button" disabled  *ngIf="isLoading"> 
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  {{'changePassword'|translate}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div id="fillW9Form"  class="row" *ngIf="isActiveW9">
      <form [formGroup]="uploadW9Form" (ngSubmit)="submitW9Form()" #w9Form>
        <div class="col-md-12" *ngIf="!isShowW9Form">
           <section class="row my-3">
            <div class="fs-6 px-0">
              <ol class="red-asterisk-list d-flex px-0">
                <span class="text-danger fs-5 mx-1 fw-bold">*</span> 
                <li class="fs-8">{{'fillW-9Form'|translate}}</li>
              </ol>
            </div>
           </section>
           <div class="w9form-download text-end">
            <a href="../../../../../assets//W-9-form.pdf" download>
              <!-- <button type="button" class="btn btn-secondary btn-sm md-font">{{'downloadForm'|translate}}</button> -->
              <button class="btn btn-primary btn-sm" type="button">
                <span><i class="fa-solid fa-download"></i></span>
                <span role="status" class="mx-2">{{'W-9Form'|translate}}</span>
              </button>
            </a>
          </div>
          <div class="row mt-2">
            <div class="col-md-4">
              <div class="mb-3 txt-group" [ngbTooltip]="'firstName'|translate">
                <div [ngClass]="uploadW9Form.get('firstName')?.invalid && uploadW9Form.get('firstName')?.touched ? 'invalid-input input-group':'input-group' ">
                  <input type="text" class="form-control revalidation-password" (keypress)="validateInputText($event)" [placeholder]="'firstName'|translate" formControlName="firstName">
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 txt-group" [ngbTooltip]="'lastName'|translate">
                <div  [ngClass]="uploadW9Form.get('lastName')?.invalid && uploadW9Form.get('lastName')?.touched ? 'invalid-input input-group':'input-group' ">
                  <input type="text" class="form-control revalidation-password" (keypress)="validateInputText($event)" [placeholder]="'lastName'|translate" formControlName="lastName">
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 txt-group" [ngbTooltip]="'ssnNo'|translate">
                <div [ngClass]="uploadW9Form.get('ssn')?.invalid && uploadW9Form.get('ssn')?.touched ? 'invalid-input input-group':'input-group' ">
                  <input (paste)="false" inputmode="numeric"  type="text" class="form-control revalidation-password" (keypress)="validationMobile($event)" [placeholder]="'ssnNo'|translate" formControlName="ssn">
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="mb-3 txt-group" [ngbTooltip]="'address'|translate">
                <div [ngClass]="uploadW9Form.get('address')?.invalid && uploadW9Form.get('address')?.touched ? 'invalid-input input-group':'input-group' ">
                  <input type="text" class="form-control revalidation-password"  [placeholder]="'address'|translate" formControlName="address">
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <div class="mb-3 txt-group" [ngbTooltip]="'city'|translate">
                <div [ngClass]="uploadW9Form.get('city')?.invalid && uploadW9Form.get('city')?.touched ? 'invalid-input input-group':'input-group' ">
                  <input type="text" class="form-control revalidation-password" (keypress)="validateInputText($event)" [placeholder]="'city'|translate" formControlName="city">
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 txt-group" [ngbTooltip]="'state'|translate">
                <div [ngClass]="uploadW9Form.get('state')?.invalid && uploadW9Form.get('state')?.touched ? 'invalid-input input-group':'input-group' ">
                  <input type="text" class="form-control revalidation-password" (keypress)="validateInputText($event)" [placeholder]="'state'|translate" formControlName="state">
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <input type="file" formControlName="w9Form" id="selectW9Form" class="d-none" accept=".pdf,.jpeg,.jpg"  (change)="onFileSelected($event)">
              <label for="selectW9Form" class="mb-3 uploadedFile w-100">
                <div [ngClass]="uploadW9Form.get('w9Form')?.invalid && uploadW9Form.get('w9Form')?.touched ? 'invalid-input input-group align-center w9form-pb1':'input-group align-center w9form-pb1' ">
                  <label class="mx-2" *ngIf="!changeUploadedBtn && !isSubmitW9Form" for="selectW9Form">
                    <span class="btn btn-secondary btn-sm" *ngIf="!fileError">Choose File</span>
                  </label>
                   <label class="addtext-ellipsis" for="selectW9Form" [ngbTooltip]="w9FormFile || fw9Url ?'Edit File':''"  [ngClass]="fw9Url ?' w-75 fs-6 pb-2':' fs-6 pb-2'">{{truncatedText}}</label>
                   <span *ngIf="fw9Url" placement="top" class="ml-auto pb-1" [ngbTooltip]="'Download' + ' '+ downloadFileName">
                    <a target="_blank" [href]="fw9Url" download>
                      <!-- <button type="button" class="btn btn-secondary btn-sm md-font">{{'downloadForm'|translate}}</button> -->
                      <a target="_blank" [href]="fw9Url" download>
                        <button type="button" class="btn btn-success btn-sm py-0">
                          <span><i class="fa-solid fa-download"></i></span>
                        </button>
                      </a>
                    </a>
                   </span>
                </div>
                <label  >
                  <div class="error-message fs-8" [ngClass]="fileError ?'text-danger':''">
                   <span class="text-danger">*</span> Select only PDF file or JPEG format.
                  </div>
                </label>
              </label>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="mb-1 mt-1 px-2 w9Forms-btn">
                <!-- <label for="selectW9Form" class="btn btn-primary btn-sm md-font" *ngIf="!changeUploadedBtn && !isSubmitW9Form">
                  <span><i class="fa-solid fa-upload"></i></span>
                  <span role="status" class="mx-2">{{'uploadForm'|translate}}</span>
                </label>
                <label for="selectW9Form"  class="btn btn-success btn-sm md-font" *ngIf="isSubmitW9Form && !changeUploadedBtn">
                  <span role="status" class="mx-2">Update Form</span>
                </label>
                <label for="selectW9Form"  class="btn btn-success btn-sm md-font" *ngIf="changeUploadedBtn">
                  <span><i class="fa-solid fa-check"></i></span>
                  <span role="status" class="mx-2">{{'formUploaded'|translate}}</span>
                </label>
               -->
                <button type="submit" class="btn btn-primary btn-sm md-font" *ngIf="!isShowFormLoader">{{'submit'|translate}}</button>
                <button class="btn btn-primary btn-sm md-font" type="button" disabled *ngIf="isShowFormLoader">
                  <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                  <span class="mx-2" role="status">{{'loading'|translate}}</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 profileimg-header" *ngIf="isShowW9Form">
          <div class="row">
            <div class="center-div pt-4 mx-width-700">
              <div class="unverified-email">
               <div class="text-center text-primary mb-2">
                 <img class="rd-level-img" src="../../../../../assets/image/check.png" alt="">
               </div>
               <div class="mt-4">
                <div class="fs-6 text-secondary">{{w9FormMessage}}</div>
              </div>
                <span class="text-secondary fs-6">{{'updateW9Form'|translate}} <a class="text-primary redirectlogin cursor-pointer"
                (click)="onUpdateW9Form()">{{'clickHere'|translate}}</a>
              </span>
              </div>
             </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="page-footer"></div>
</div>