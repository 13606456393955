<div class="container-fluid welcome">
    <header class="sticky-top">
        <nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light border-bottom box-shadow header">
          <div class="container-fluid">
            <a class="navbar-brand">
              <img class="mx-1 logo-landingpage" src="../assets/icons/dashboard-logo-icon.png" />
            </a>
          </div>
          <hr />
        </nav>
      </header>

      <div class="welcome-center">
        <h4 class="mb-3">Welcome to Our Community!</h4>
        <p>Discover, learn, and connect with like-minded individuals. Your journey starts here!</p>
      </div>

      <footer>
             <div class="welcome-list row p-2">
                <div class="col-md-4">
                    <a routerLink="/privacy-policy/terms-conditions">Terms and conditions</a>
                </div>
                <div class="col-md-4">
                    <a routerLink="/contact-us">Contact Us</a>
                </div>
                <div class="col-md-4">
                    <a routerLink="/privacy-policy">Privacy Policy</a>
                </div>
            </div>
            <div class="welcome-address">
                <div>
                    <p>Address : 19 West, 34th Street, Ste.1018,New York, NY - 10001</p>
                </div>
            </div>
      </footer>
</div>
