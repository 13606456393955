<div class="container-fluid">
  <div class="row fixed-topheader">
    <div class="row sticky-top">
      <div class="d-flex justify-content-between dashboard-mainboady">
        <div class="dashboard-heading">
          <h3>{{'mySurvey'|translate}}</h3>
        </div>
        <div class="dashboard-headingicons">
          <app-header></app-header>
        </div>
      </div>
      <hr class="dashboardhr-line" />
    </div>
  </div>
  <section class="getApp-toast" *ngIf="isShowAToast && isToastPops">
    <div class="toast fade show" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="toast-header p-0">
        <button type="button" class="btn-close m-0" data-bs-dismiss="toast" aria-label="Close" (click)="closeToast()"></button>
        <img class="mx-1" src="../../../../../assets/image/unipanel-icon.png" alt="">
        <div class="mx-1">
          <strong class="mx-1 text-primary">{{'opinionEdgeApp' | translate}}</strong>
          <div><small>{{'shareOpinion'|translate}}</small></div>
        </div>
        <button class="btn btn-primary btn-sm ml-auto" (click)="downloadApp()">{{'download'|translate}}</button>
        </div>
    </div>
  </section>
  <div class="row card-survey fixed-body" *ngIf="!isLoader">
    <div class="d-flex point-container mb-3">
      <div class="point">{{'balancePoints'|translate}}</div>
      <div class="point-balance">{{pointBalance}}</div>
    </div>
    <div class="mb-3">
      <ul class="nav personal-detail-nav">
        <li class="nav-item mySurvey-tab m-0" [ngClass]="myOffer? 'personaldetails':'user-profile'" (click)="selectButton('myOffer')">
          <a>{{'myOffer'|translate}}</a>
        </li>
        <li class="nav-item mySurvey-tab" [ngClass]="!miniOffer? 'user-profile':'personaldetails'" (click)="selectButton('miniOffer')">
          <a>{{'miniOffer'|translate}}</a>
        </li>
      </ul>
    </div>
    <div *ngIf="isEmailCheck">
      <section>
        <div *ngIf="isEmailVerified; else notVerified">
          <div class="row">
            <div class="col-md-4" *ngFor="let item of surveyData">
            <div class="card">
              <div class="d-flex justify-content-between">
                <div>
                  <div class="row">
                    <span class="fs-6 survey-code">{{item.surveyCode}}</span>
                  </div>
                  <img class="w-50" src="../assets/image/card-bg-icon.png" />
                </div>
                <div class="text-end">
                  <span><i class="fa-solid fa-database text-primary fs-5"></i></span>
                  <span class="card-text">{{item.completedPoints}}</span>
                  <div class="text-primary">
                    <span [ngClass]="item.isMobile ?'' :'show-mobile'"><i class="fa-solid fa-mobile-screen-button"></i></span>
                    <span [ngClass]="item.isIpad ?'mx-3':'show-mobile mx-3'"><i class="fa-solid fa-tablet-screen-button"></i></span>
                    <span [ngClass]="item.isDesktop ?'':'show-mobile'"><i class="fa-solid fa-desktop"></i></span>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-between mt-auto">
                <div class="text-center mb-2 survey-time">
                  <span class="text-primary fs-6"><i class="fa-regular fa-clock"></i></span>
                  <span class="survey-minut">{{item.LOI}}</span>
                </div>
                <div class="text-center">
                  <div class="d-flex survey-btn-align">
                    <div [ngClass]="item.isDisabled || isDisabled  ? 'disabled' : ''" placement="top" [ngbTooltip]="getTooltipText(item)" *ngIf="!item.isButtonClicked">
                      <a class="btn btn-primary card-button" (click)="getSurvey(item.surveyId, item.clientUrl, item);">
                        {{'start'|translate}}
                      </a>    
                    </div>
                    <div *ngIf="item.isButtonClicked">
                      <button class="btn btn-primary card-button" disabled>
                        <div class="spinner-border spinner-border-sm" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center text-secondary mt-5" *ngIf="showDataMessage">
            {{'emptySurveyMessage'|translate}}
         </div>
          </div>
        </div>
      </section>
      
      <ng-template #notVerified>
        <div class="page-container">
          <div class="row blurred" [class.visible]="!isEmailVerified">
            <div class="col-md-4" *ngFor="let item of surveyData">
              <div class="card">
                <div class="d-flex justify-content-between">
                  <div>
                    <div class="row">
                      <span class="fs-6 survey-code">{{item.surveyCode}}</span>
                    </div>
                    <img class="w-50" src="../assets/image/card-bg-icon.png" />
                  </div>
                  <div class="text-end">
                    <span><i class="fa-solid fa-database text-primary fs-5"></i></span>
                    <span class="card-text">{{item.completedPoints}}</span>
                    <div class="text-primary">
                      <span [ngClass]="item.isMobile ?'' :'show-mobile'"><i class="fa-solid fa-mobile-screen-button"></i></span>
                      <span [ngClass]="item.isIpad ?'mx-3':'show-mobile mx-3'"><i class="fa-solid fa-tablet-screen-button"></i></span>
                      <span [ngClass]="item.isDesktop ?'':'show-mobile'"><i class="fa-solid fa-desktop"></i></span>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-between mt-auto">
                  <div class="text-center mb-2 survey-time">
                    <span class="text-primary fs-6"><i class="fa-regular fa-clock"></i></span>
                    <span class="survey-minut">{{item.LOI}}</span>
                  </div>
                  <div class="text-center">
                    <div class="d-flex survey-btn-align">
                      <div class="disabled" placement="top">
                        <a class="btn btn-primary card-button disabled">
                          {{'start'|translate}}
                        </a>    
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center text-secondary mt-5" *ngIf="showDataMessage">
              {{'emptySurveyMessage'|translate}}
           </div>
          </div>
          <div class="overlay" [class.visible]="!isEmailVerified">
            <div class="verification-div d-flex justify-content-center mb-2">
              <div class="text-center overlay-bg m-3 fs-6">
                <div class="verifymessage-box fs-6">
                  {{'surveyEmailVerifyMessage'|translate}}
                </div>
                <strong>{{'notReceiveEmail' | translate}}</strong>
                <div><span class="btn btn-primary btn-sm cursor-pointer" (click)="sendEmailButton()">{{'resend'|translate}}</span></div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>

  <section class="row card-survey fixed-body" *ngIf="isLoader">
      <div class="d-flex justify-content-center mt-5">
        <div class="loader">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
  </section>

  <div class="page-footer"></div>
</div>
