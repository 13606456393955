<div class="container-fluid">

  <div class="row fixed-topheader">
    <div class="row sticky-top">
      <div class="d-flex justify-content-between dashboard-mainboady">
        <div class="dashboard-heading">
          <h3>{{'profileQuestion'|translate}}</h3>
        </div>
        <div class="dashboard-headingicons">
          <app-header></app-header>
        </div>
      </div>
      <hr class="dashboardhr-line" />

    </div>
  </div>
  <div class="row fixed-body profile-questiondiv mx-0" *ngIf="!isShowError">
    <div class="row mb-3 fs-6">
      {{'profileQuestionTextMessage'|translate}}
    </div>
  <div class="questions" *ngIf="isShowQuestions && !isShowError">
        <!--question-tabs-->
        <div class="row profile-question-tabs">
          <ul class="nav">
            <li [ngClass]="i === isShowTab ?'nav-item selected-tab mb-2':'nav-item first-nav mb-2'"
              *ngFor="let item of questions; let i = index" (click)="getQuestions(item.categoryId,i)">
              <img class=" questionImg" [src]="item.categoryImage" alt="">
              <span class="fs-6 questionTitle">{{item.category}}</span>
            </li>
          </ul>
        </div>
           <!--question-tabs end-->
           
        <!-- show Error message -->
        <div class="text-center text-secondary mt-5" *ngIf="isShowError">{{errorMessage}}</div>
    
        <div class="row profile-question-content">
          <!--Profile Questions-->
          <div class="row automobile">
            <div class="container fs-6">
              <form [formGroup]="userProfileQuestionForm" (ngSubmit)="userResponseNew()">
              <div ngbAccordion [closeOthers]="true" class="accordion accordion-flush">
                <div ngbAccordionItem *ngFor="let item of profileQuestion; let i = index" [collapsed]="item !== profileQuestion[0]" class="accordion-item border-bottom-0">
                  <h2 ngbAccordionHeader class="accordion-header rounded">
                    <button ngbAccordionButton  class="accordion-button p-4 rounded z-0">{{item.qsnStatement}}</button>
                  </h2>
                  <div ngbAccordionCollapse class="row container">
                    <div ngbAccordionBody *ngFor="let option of item.option; index as singleIndex" class="col-md-4 col-sm-12">
                      <ng-template>
                        <div class="form-check cursor-pointer">
                          <input class="form-check-input " [id]="'btn' + singleIndex + i" [checked]="option.isSelected === true" [name]="item.masterQsnId" [type]="item.questiontype==='Single Punch'?'radio':'checkbox'" (change)="getUserAnswerValue(option,item,$event)" formControlName = "userResponseValue">
                          <label class="form-check-label" [for]="'btn' + singleIndex + i">{{ option.optionStatement }}</label>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </div>
                <button type="submit" class="btn btn-primary btn-sm float-end mt-3 mx-4 questionsubmit-btn" [disabled]="!isSubmit">{{'submit'|translate}}</button>
              </div>
            </form>
            </div>
    
          </div>
    
        </div>
  </div>
  </div>
  <div class="alert alert-primary mt-5" role="alert" *ngIf="isShowError">
    {{'loadingMessage'|translate}}
   </div>
  <div class="page-footer"></div>
</div>