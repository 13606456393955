<app-nav-menu></app-nav-menu>
<div class="container-fluid text-secondary">
  <div class="center-div mx-width-700" *ngIf="!isShowSubscribe">
    <div class="unverified-email">
      <div>
        <img class="rd-level-img mb-2" src="../../../../../assets/image/sad-img.jpg" alt="">
      </div>
      <div>
        {{'unsubscribeMessage'|translate}}
      </div>
      <div class="mt-4" *ngIf="!isShowSubscribe">
        <div class="mt-3 text-center"><button class="btn btn-primary btn-sm unsubscribeBtn"
            (click)="unSubscribeEmail()">{{'unsubscribe'|translate}}</button></div>
      </div>
    </div>
  </div>
  <div class="center-div mx-width-700" *ngIf="isShowSubscribe">
    <div class="unverified-email">
      <div class="text-center text-primary mb-2">
        <img class="rd-level-img" src="../../../../../assets/image/check.png" alt="">
      </div>
      <div class="fw-bold">{{'unsubscribedMesOne'|translate}}</div>
      <h6>{{'unsubscribedMesTwo'|translate}}</h6>
    </div>
  </div>

</div>