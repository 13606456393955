<div class="container-fluid">
  <div class="row fixed-topheader">
    <div class="row sticky-top">
      <div class="d-flex justify-content-between dashboard-mainboady">
        <div class="dashboard-heading">
          <h3>{{'referEarn'|translate}}</h3>
        </div>
        <div class="dashboard-headingicons">
          <app-header></app-header>
        </div>
      </div>
      <hr class="dashboardhr-line" />
    </div>
  </div>

  <div class="row fixed-body">
    <div class="row fs-6">
      <span>{{'referMessage'|translate}}</span>
    </div>
    <form [formGroup]="sendReferLinkForm" (ngSubmit)="sendReferEmail()">
      <section class="row px-1 mt-1 referwithemail">
        <div class="col-md-4">
          <div class="mb-3 txt-group px-0">
            <!-- <label class="form-label">{{'name'|translate}}</label> -->
            <input type="text" [placeholder]="'name'|translate" class="form-control fs-6" formControlName="name" (keypress)="validateInputText($event)">
          </div>
        </div>
        <div class="col-md-4 ">
          <div class="mb-3 txt-group px-0">
            <!-- <label class="form-label ">{{'email'|translate}}</label> -->
            <input type="email" [placeholder]="'emailId'|translate" class="form-control fs-6" formControlName="email">
          </div>
        </div>
        <div class="col-md-4 text-end p-3">
          <button class="btn btn-primary btn-sm m-auto w-75" type="submit" [disabled]="sendReferLinkForm.invalid">{{'submit'|translate}}</button>
        </div>
      </section>
    </form>
    <section class="mtb-4">
      <div class="row px-3 fs-6">
        {{'shareLinkMessage'|translate}}
      </div>
      <div class="row px-2" title="Copy to clip board">
        <div class="mb-3 txt-group" (click)="copyToClipboard(referLink)">
          <label class="form-label">{{'link'|translate}}</label>
          <div class="d-flex">
            <div class="w-75 fs-6">{{referLink}}</div>
            <span data-bs-toggle="tooltip" *ngIf="showMessage">
              <i class="fa-regular fa-copy"></i>
            </span>
            <span class="form-label" *ngIf="!showMessage">
              {{copiedMessage}}
            </span>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="row">
        <div class="dashboardbody">
          <h3>{{'referredMembers'|translate}}</h3>
        </div>
      </div>
      <div class="row dashboard-table">
        <table class="table mb-4">
          <thead class="table-heading">
            <tr class="tr-bottom-primary th-w-25">
              <th>{{'name'|translate}}</th>
              <th>{{'emailId'|translate}}</th>
              <th>{{'status'|translate}}</th>
              <!-- <th>{{'pointsEarned'|translate}}</th> -->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of referHistory">
              <td>{{item.firstName}} {{item.lastName}}</td>
              <td>{{item.email}}</td>
              <!-- <td>{{item.joinDate | date:'dd/MM/yyyy'}}</td> -->
              <td>{{ item.status == 2 ? ('joined'|translate) : ('pending'|translate)}}</td>
              <!-- <td>{{item.points}}</td> -->
            </tr>
          </tbody>
        </table>
        <div class="text-center text-secondary">
          {{emptyMessage}}
        </div>
        <hr />

        <div class="row dashboard-table-pagination">

        </div>
        <!-- <div class="row mt-3 mb-3">
          <span class="show-all-survey">
            <a href="#">{{'referEarn'|translate}} </a>
          </span>
        </div> -->
      </div>
      <!-- <div class="d-flex total-rpoint">
        <span>{{'totalReferralPoint'|translate}}</span>
        <span class="total-rpoint-span">{{totalReferPoint}}</span>
      </div> -->
    </section>
  </div>
  <div class="page-footer"></div>
</div>