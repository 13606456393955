import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './modules/users/components/login/login.component';
import { HeaderComponent } from './modules/base/components/header/header.component';
import { FooterComponent } from './modules/base/components/footer/footer.component';
import { DashboardComponent } from './modules/users/components/dashboard/dashboard.component';
import { CreateAccountComponent } from './modules/base/components/create-account/create-account.component';
import { ContactUsComponent } from './modules/base/components/contact-us/contact-us.component';
import { BodyPageComponent } from './modules/base/components/body-page/body-page.component';
import { LeaderboardComponent } from './modules/users/components/leaderboard/leaderboard.component';
import { MySurveyComponent } from './modules/users/components/my-survey/my-survey.component';
import { NavMenuComponent } from './modules/base/components/nav-menu/nav-menu.component';
import { PersonalDetailComponent } from './modules/users/components/personal-detail/personal-detail.component';
import { PointDetailComponent } from './modules/users/components/point-detail/point-detail.component';
import { PrivacyPolicyComponent } from './modules/base/components/privacy-policy/privacy-policy.component';
import { ProfileQuestionComponent } from './modules/users/components/profile-question/profile-question.component';
import { RedeemPointComponent } from './modules/users/components/redeem-point/redeem-point.component';
import { ReferEarnComponent } from './modules/users/components/refer-earn/refer-earn.component';
import { SidenavComponent } from './modules/users/components/sidenav/sidenav.component';
import { TermsConditionsComponent } from './modules/base/components/terms-conditions/terms-conditions.component';
import { AutomobileComponent } from './modules/users/components/profile-question-category/automobile/automobile.component';
import { FoodComponent } from './modules/users/components/profile-question-category/food/food.component';
import { HealthComponent } from './modules/users/components/profile-question-category/health/health.component';
import { ProfileComponent } from './modules/users/components/profile-question-category/profile/profile.component';
import { ShoppingComponent } from './modules/users/components/profile-question-category/shopping/shopping.component';
import { TechnologyComponent } from './modules/users/components/profile-question-category/technology/technology.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TokenInterceptor } from './base/interceptor/token.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, HashLocationStrategy, CurrencyPipe } from '@angular/common';
import { CallbackSurveyComponent } from './modules/base/components/callback-survey/callback-survey.component';
import { ForgotPasswordComponent } from './modules/base/components/forgot-password/forgot-password.component';
import { EmailVerifyComponent } from './modules/base/components/email-verify/email-verify.component';

import {
  FingerprintjsProAngularModule
} from '@fingerprintjs/fingerprintjs-pro-angular'
import { FingerPrintComponent } from './modules/fingerprint/fingerprint.component';
import { LandingPageComponent } from './modules/landing-page/landing-page.component';
import { CustomToastComponent } from './modules/base/components/custom-toast/custom-toast.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UserPrivacyPolicyComponent } from './modules/users/components/user-privacy-policy/user-privacy-policy.component';
import { UserContactUsComponent } from './modules/users/components/user-contact-us/user-contact-us.component';
import { SurveyLandingComponent } from './modules/base/components/survey-landing/survey-landing.component';
import { UnsubscribeComponent } from './modules/base/components/unsubscribe/unsubscribe.component';
import { WelcomePageComponent } from './modules/base/components/welcome-page/welcome-page.component';
import { GdiComponent } from './modules/base/components/gdi/gdi.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { AppInstallationComponent } from './modules/base/components/app-installation/app-installation.component';
import { FAQSComponent } from './modules/base/components/faqs/faqs.component';
import { NewLinePipe } from './modules/base/models/new-line.pipe';
import { UserFaqsComponent } from './modules/users/components/user-faqs/user-faqs.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    DashboardComponent,
    CreateAccountComponent,
    ContactUsComponent,
    BodyPageComponent,
    LeaderboardComponent,
    MySurveyComponent,
    NavMenuComponent,
    PersonalDetailComponent,
    PointDetailComponent,
    PrivacyPolicyComponent,
    ProfileQuestionComponent,
    RedeemPointComponent,
    ReferEarnComponent,
    SidenavComponent,
    TermsConditionsComponent,
    AutomobileComponent,
    FoodComponent,
    HealthComponent,
    ProfileComponent,
    ShoppingComponent,
    TechnologyComponent,
    CallbackSurveyComponent,
    ForgotPasswordComponent,
    EmailVerifyComponent,
    FingerPrintComponent,
    LandingPageComponent,
    CustomToastComponent,
    UserPrivacyPolicyComponent,
    UserContactUsComponent,
    SurveyLandingComponent,
    UnsubscribeComponent,
    WelcomePageComponent,
    GdiComponent,
    AppInstallationComponent,
    FAQSComponent,
    NewLinePipe,
    UserFaqsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule, ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoaderh,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      // preventDuplicates: true,
    }),
    FingerprintjsProAngularModule.forRoot({
      loadOptions: {
        apiKey: "ZNcV1vVkyZX7kHbYd2p6",
        endpoint: [
          "https://fp.opinion-edge.com"
        ],
        scriptUrlPattern: [
          "https://fp.opinion-edge.com/web/v3/ZNcV1vVkyZX7kHbYd2p6/loader_v3.9.3.js"
        ]
      }
    }),
    NgbModule,
    NgxCaptchaModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled:environment.production})
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  },
  {
    provide: LocationStrategy,
    useClass: HashLocationStrategy
  },
  CurrencyPipe
],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function httpTranslateLoaderh(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
