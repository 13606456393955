<section *ngIf="userNotLogedIn"><app-nav-menu></app-nav-menu></section>
<hr class=" container-fluid nav-devider" *ngIf="userNotLogedIn">
<div class="container-fluid faqs-box py-3">
    <div class="row">
        <div class="text-center faqs-hed">
            <h1>{{'faqs' |translate}}</h1>
            <p>{{'gotQuestions'|translate}}</p>
            <hr>
        </div>
    </div>
    <div class="container">
        <section class="row">
            <ul class="nav privacy-policy-tabs fasq-tabs mb-3">
                <li class="nav-item" (click)="isShowTab('overview')">
                  <a class="px-3" [ngClass]="!showOverview?'nav-link text-secondary cursor-pointer':'nav-link active'"
                    aria-current="page">{{'overview' | translate}}</a>
                </li>
                <li class="nav-item" (click)="isShowTab('account')">
                  <a class="px-3" [ngClass]="!showAccount?'nav-link text-secondary cursor-pointer':'nav-link active'">{{'accountTab' | translate}}</a>
                </li>
                <li class="nav-item" (click)="isShowTab('surveys')">
                  <a class="px-3" [ngClass]="!showSurveys?'nav-link text-secondary cursor-pointer':'nav-link active'">{{'surveysTab' | translate}}</a>
                </li>
                <li class="nav-item" (click)="isShowTab('rewards')">
                  <a class="px-3" [ngClass]="!showRewards?'nav-link text-secondary cursor-pointer':'nav-link active'">{{'rewards_create'| translate}}</a>
                </li>
                <li class="nav-item" (click)="isShowTab('privacy')">
                  <a class="px-3" [ngClass]="!showPrivacy?'nav-link text-secondary cursor-pointer':'nav-link active'">{{'privacyTab' |translate}}</a>
                </li>
              </ul>
        </section>
    
        <section class="row">
            <div ngbAccordion [closeOthers]="true">
                <div ngbAccordionItem *ngFor="let item of Questions;let i = index;" [collapsed]="i !== 0">
                    <h2 ngbAccordionHeader>
                        <button ngbAccordionButton>{{item.question | newLine}}</button>
                    </h2>
                    <div ngbAccordionCollapse>
                        <div ngbAccordionBody>
                           <div [innerHTML]="item.answer | newLine"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>