<header class="sticky-top">
  <nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light border-bottom box-shadow header">
    <div class="container-fluid">
      <a class="navbar-brand">
        <img class="mx-1 logo-landingpage" src="../assets/icons/dashboard-logo-icon.png" />
      </a>
    </div>
    <hr />
  </nav>
</header>
<div class="text-center mt-3">
  <h3 class="text-primary fw-bold">{{'yourOpinion'|translate}}</h3>
  <h3 class="text-primary fw-bold">{{'yourRewards'|translate}}</h3>
</div>
<div class="center-div">
  <p class="mb-3 p-2">
    {{'landingPageMessage'|translate}}
  </p>
  <div class="mt-3">
    <div class="text-center mx-2">
    </div>
    <div class="text-center">
      <img class="landing-page-imgicon" src="../assets/image/android_icon.png" alt="">
      <img class="landing-page-imgicon mx-2" src="../assets/image/ios_icon.png" alt="">
    </div>
    <button class="btn btn-primary redirect-landingbtn" (click)="saveAffiliateData()" *ngIf="!isDisabled">
      {{'joinThePanel'|translate}} <span><i class="fa-solid fa-arrow-right"></i></span>
    </button>
    <button class="btn btn-primary redirect-landingbtn" type="button" disabled *ngIf="isDisabled">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      {{'joinThePanel'|translate}} <span><i class="fa-solid fa-arrow-right"></i></span>
    </button>
  </div>
</div>