<div class="container-fluid">
    <div class="row fixed-topheader">
        <div class="row sticky-top">
          <div class="d-flex justify-content-between dashboard-mainboady">
            <div class="dashboard-heading">
              <h3>{{'FAQues' | translate}}</h3>
            </div>
            <div class="dashboard-headingicons">
              <app-header></app-header>
            </div>
          </div>
          <hr class="dashboardhr-line" />
        </div>
      </div>

      <div class="row fixed-body">
        <div class="container-fluid">
            <app-faqs></app-faqs>
        </div>
      </div>
</div>