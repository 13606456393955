<div class="container">
  <div class="accordion accordion-flush" *ngFor="let item of profileQuestion; let i = index">
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
          [attr.data-bs-target]="'#automobile'+i" aria-expanded="false" [attr.aria-controls]="'automobile'+i">
          {{item.qsnStatement}}
        </button>
      </h2>
      <ng-container *ngIf="item.questiontype == 'Single Punch'; else maltiPunch">
        <div [id]="'automobile'+ i" class="accordion-collapse collapse" *ngFor="let options of item.option">
          <div class="row questions">
            <div class="form-check mb-3">
              <label class="form-check-label">
                <input class="form-check-input" type="checkbox" value="">
                {{options.optionStatement}}
              </label>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #maltiPunch>
        <ng-container *ngIf="item.questiontype == 'Multi Punch'; else txtQuestion">
          <div [id]="'automobile'+ i" class="accordion-collapse collapse" *ngFor="let options of item.option">
            <div class="row questions">
              <div class="form-check mb-3">
                <label class="form-check-label">
                  <input class="form-check-input" type="checkbox" value="">
                  {{options.optionStatement}}
                </label>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-template>
      <ng-template #txtQuestion>
        <div [id]="'automobile'+ i" class="accordion-collapse collapse">
          <div class="row questions">
            <div class="form-check mb-3">
              <label class="form-check-label">
                <input class="form-control" [type]="item.questiontype.toLowerCase()">
              </label>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
<div class="row">
  <div class="text-end">
    <button class="btn btn-primary btn-sm questionsubmit-btn">Submit</button>
  </div>
</div>