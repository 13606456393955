import { Component } from '@angular/core';

@Component({
  selector: 'app-health',
  templateUrl: './health.component.html'
})
export class HealthComponent {
  items: string[] = [
    'Do you have a any medical issue', 'what you have illness',
  ]
}
