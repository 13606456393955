import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { userApi } from 'src/app/base/model/constants/ApiEndPoints';
import { RecaptchaSiteKey } from 'src/app/base/model/constants/AppConstants';
import { BaseService } from 'src/app/base/service/base.service';
import { UserService } from 'src/app/modules/users/services/user.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html'
})

export class ContactUsComponent implements OnInit {

  constructor(private _baseService: BaseService,
    private fb: FormBuilder,
    private router:Router,
    private toastr:ToastrService,
    private translate:TranslateService,
    private userService:UserService
  ) { }
  userSupportForm: FormGroup;
  ticketResponse: string;
  selectedFile: { file: File, dataUrl: string }[] = [];
  picFileName: string[] = [];
  recaptchaSiteKey = RecaptchaSiteKey.siteKey;
  getCulture;
  ngOnInit() {
    const emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.userSupportForm = this.fb.group({
      email: new FormControl('', [Validators.required,Validators.email,Validators.pattern(emailPattern)]),
      subject: new FormControl('', Validators.required),
      selectFile: new FormControl(''),
      textMessage: new FormControl('', Validators.required),
      // recaptcha: ['', Validators.required]
    })

    if (localStorage.getItem('access_token')) {
      this.router.navigate(['login/body/dashboard'])
    }

    this.userService.countryCultureSource.subscribe(country => {
      this.getCulture = country;      
    })
  }
  onSubmitrequest() {
    const fileData = new FormData();
    const recaptcha = this.userSupportForm.value.recaptcha;
    const reCaptchaTokenBody = {
      token: recaptcha
    }
    fileData.append('email', this.userSupportForm.value.email);
    fileData.append('subject', this.userSupportForm.value.subject);
    for (let i = 0; i < this.selectedFile.length; i++) {
      fileData.append('image', this.selectedFile[i].file);
    }
    fileData.append('text', this.userSupportForm.value.textMessage);
    this._baseService.post(userApi.supportTicket, fileData).then((res: any) => {
      this.translate.get('supportMail').subscribe((translate:string)=>{
        this.toastr.success(translate, '', {
          toastClass: 'success-toast',
          timeOut: 3000,
        })
       })
      // this.ticketResponse = res.message;
    }), err => {
      this.toastr.error(err.message)
      // this.ticketResponse = err.error.message;
    }

    this.userSupportForm.reset();
    this.picFileName = [];
    this.selectedFile = [];
  }

  onFileSelected(event) {
    for (let i = 0; i < event.target.files.length; i++) {
      const file:File = event.target.files[i];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.selectedFile.push({
            file: file,
            dataUrl: reader.result as string
        });
    };
    this.picFileName.push(file.name);
    }

  }

  cancelSelectedPhoto(index){
    this.selectedFile.splice(index, 1);
    this.picFileName.splice(index, 1);
  }

  isImageType(fileType: string): boolean {
    return fileType.startsWith('image/');    // Check if the file type starts with 'image/'
  }
}
