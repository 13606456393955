<div class="sidebar-div">
  <div class="row">
    <aside class="main-sidebar">
      <div class="fixed-topsidenav">
        <div class="d-flex justify-content-between logo-container">
          <div>
            <img class="mx-1 logo-img cursor-pointer" src="../assets/icons/dashboard-logo-icon.png" alt="" routerLink="/login" />
          </div>
          <div class="text-center mt-1 fs-5 cursor-pointer">
            <button type="button" class="btn-close offcanvas-close mx-2" data-bs-dismiss="offcanvas"
              aria-label="Close"></button>
          </div>
        </div>
        <div class="profile-status">
          <div class="row profile-div align-center w-100">
            <div class=" col-md-4 text-center profile-image ml-2">
              <img src="{{profileImage}}" alt="" />
            </div>
            <div class="col-md-8 mt-2 profile-description">
              <div class="row">
                <div class="d-flex justify-content-between px-1">
                  <div>
                   <h3 class="profile-name">{{fullName}}</h3>
                  </div>
                  <div>
                   <span class="fs-5 mx-3">
                     <span class="{{'fi fi-'+ countryCode}}"></span>
                   </span>
                 </div>
               </div>
              </div>
              <div class="row">
                <p class="profile-email px-1" id="panelistEmail">{{email}}</p>
              </div>
              <div class="d-flex justify-content-between country-status">
                <div>
                  <!-- <span class="fs-5">
                    <span class="{{'fi fi-'+ countryCode}}"></span>
                  </span> -->
                </div>
                <!-- <div class="cursor-pointer" title="Profile Setting" routerLink="/login/body/personal-detail"
                >
                  <span class="fs-5 profile-setting">
                    <i class="fa-solid fa-gear"></i>
                  </span>
                </div> -->
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between profile-level-status">
            <div class="profilepoint-heading ">
             <span> {{'totalPoints'|translate}} </span>
              <span class="profile-point mx-1"> {{point}} </span>
            </div>
              <div class="user-level d-flex justify-content-around">
                {{level}}
                <span placement="start" [ngbTooltip]="getTooltipText()">
                  <span class="i-icon p-0">
                    <span>i</span>
                 </span>
                </span>
              </div>
          </div>
        </div>
      </div>
      <section class="sidebar mx-3 px-0 fixed-sidenav-body">
        <ul class="sidebar-menu">
          <li routerLink="/login/body/dashboard"
            [routerLinkActive]="['active-page']" [routerLinkActiveOptions]="{ exact: false }"
            class="my-2 cursor-pointer">
            <a class="sidenav-link">
              <i class="fa-solid fa-table-columns fa-rotate-270 mr-3"></i>
              <span class="nav-label">{{'dashboard'|translate}}</span>
            </a>
          </li>
          <li routerLink="/login/body/my-survey" class="my-2 cursor-pointer"
            [routerLinkActive]="['active-page']">
            <a class="sidenav-link">
              <i class="fa-solid fa-clipboard-check mr-3"></i>
              <span class="nav-label">{{'mySurvey'|translate}}</span>
            </a>
          </li>
          <li routerLink="/login/body/profile-question/automobile"
            class="my-2 cursor-pointer" [routerLinkActive]="['active-page']">
            <a class="sidenav-link">
              <i class="fa-sharp fa-solid fa-file-circle-question mr-3"></i>
              <span class="nav-label">{{'profileQuestion'|translate}}</span>
            </a>
          </li>
          <li routerLink="/login/body/personal-detail"
            class="my-2 cursor-pointer" [routerLinkActive]="['active-page']">
            <a class="sidenav-link">
              <i class="fa-regular fa-circle-user mr-3"></i>
              <span class="nav-label">{{'myAccount'|translate}}</span>
            </a>
          </li>
          <li routerLink="/login/body/point-detail" class="my-2 cursor-pointer"
            [routerLinkActive]="['active-page']">
            <a class="sidenav-link">
              <i class="fa-sharp fa-regular fa-clock mr-3"></i>
              <span class="nav-label">{{'pointsDetails'|translate}}</span>
            </a>
          </li>
          <li routerLink="/login/body/redeem-point" class="my-2 cursor-pointer"
            [routerLinkActive]="['active-page']">
            <a class="sidenav-link">
              <i class="fa-solid fa-database mr-3"></i>
              <span class="nav-label">{{'redeemMyPoints'|translate}}</span>
            </a>
          </li>
          <li routerLink="/login/body/refer-earn"  class="my-2 cursor-pointer"
            [routerLinkActive]="['active-page']">
            <a class="sidenav-link">
              <i class="fa-sharp fa-regular fa-user fa-rotate-180 mr-3"></i>
              <span class="nav-label">{{'referEarn'|translate}}</span>
            </a>
          </li>
          <li routerLink="/login/body/user-privacy-policy" class="my-2 cursor-pointer"
            [routerLinkActive]="['active-page']">
            <a class="sidenav-link">
              <i class="fa-sharp fa-solid fa-shield-halved mr-3"></i>
              <span class="nav-label">{{'privacyPolicy'|translate}}</span>
            </a>
          </li>
          <li routerLink="/login/body/user-contact-us" class="my-2 cursor-pointer"
            [routerLinkActive]="['active-page']">
            <a class="sidenav-link">
              <i class="fa-solid fa-envelope mr-3"></i>
              <span class="nav-label">{{'contactUs'|translate}}</span>
            </a>
          </li>
          <li routerLink="/login/body/user-faqs" class="my-2 cursor-pointer"
            [routerLinkActive]="['active-page']">
            <a class="sidenav-link">
              <i class="fa-regular fa-circle-question mr-3"></i>
              <span class="nav-label">{{'faqs' | translate}}</span>
            </a>
          </li>
          <!-- <li routerLink="/login/body/leaderboard" class="my-2 cursor-pointer" [routerLinkActive]="['active-page']">
            <a class="sidenav-link">
              <i class="fa-solid fa-synagogue mr-3"></i>
              <span class="nav-label">Leaderboard</span>
            </a>
          </li> -->
        </ul>
      </section>

    </aside>
  </div>
</div>