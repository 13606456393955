<form [formGroup]="profileQsnResponseForm" (ngSubmit)="onResponseQuestion()">
  <div class="container" *ngFor="let item of profileQuestion; let i = index">
    <div class="accordion accordion-flush">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            [attr.data-bs-target]="'#profile'+i" aria-expanded="false" [attr.aria-controls]="'profile'+i">
            {{item.qsnStatement}}
          </button>
        </h2>
        <ng-container *ngIf="item.questiontype == 'Single Punch'; else txtQuestion">
          <div [id]="'profile'+ i" class="accordion-collapse collapse"
            *ngFor="let options of item.option; let i1 = index">
            <div class="row questions">
              <div class="form-check mb-3">
                <label class="form-check-label">
                  <input class="form-check-input" value="{{options.optionStatement}}" [checked]="options.checked"
                    (change)="updateSelectedOption(options,item.option)" type="checkbox">
                  {{options.optionStatement}}
                </label>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #txtQuestion >
          <div  [id]="'profile'+ i" class="accordion-collapse collapse">
            <div class="row questions" >
              <div class="form-check mb-3">
                <label class="form-check-label">
                  <input class="form-control"   [type]="item.questiontype.toLowerCase()">
                </label>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="text-end">
      <button type="submit" class="btn btn-primary btn-sm questionsubmit-btn">Submit</button>
    </div>
  </div>
</form>