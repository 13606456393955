import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { userApi } from 'src/app/base/model/constants/ApiEndPoints';
import { PanelGuId } from 'src/app/base/model/constants/AppConstants';
import { BaseService } from 'src/app/base/service/base.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html'
})
export class ProfileComponent {

  constructor(
    private _baseService: BaseService,
    private fb: FormBuilder
  ) { }

  profileQuestion;
  profileQsnResponseForm: FormGroup;
  selectedIndex: number;

  ngOnInit() {
    // Method for get profileQuestion
    const panelGuid = PanelGuId.OpinionEdge;
    const countryId = 2;
    const languageId = 1;
    const categoryId = 4;
    const panelistId = localStorage.getItem('panelistId')
    this._baseService.get(userApi.getProfileCatQsn +
      `?panelGuid=${panelGuid}&countryId=${countryId}&languageId=${languageId}&categoryId=${categoryId}&panelistId=${panelistId}`
    ).then((res: any) => {
      console.log(res)
      this.profileQuestion = res.data;

    })

    // Method for  question response
    this.profileQsnResponseForm = this.fb.group({
      selectedOption: this.fb.array([]),
      textresponse: [this.profileQuestion]
    })

  }

  // method for profile answer response form
  onResponseQuestion() {
    console.log(this.profileQsnResponseForm.value);
  }


  // Method for checkbox selection 
  updateSelectedOption(selectedOption: any, options: any[]) {
    const checkArray: FormArray = this.profileQsnResponseForm.get('selectedOption') as FormArray;
    console.log(checkArray)
    options.forEach(option => {
      if (option !== selectedOption) {
        option.checked = false;
        checkArray.push(new FormControl(option.checked));
      } else {
        let i: number = 0;
        checkArray.controls.forEach((item: any) => {
          if (item.value == option.checked) {
            checkArray.removeAt(i);
            return;
          }
          i++;
        })
      }
    });
    selectedOption.checked = true;
  }

}
