<header class="sticky-top">
  <nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light border-bottom box-shadow header">
    <div class="container-fluid">
      <a class="navbar-brand" routerLink="/">
        <img class="mx-1 logo-landingpage" src="../assets/icons/dashboard-logo-icon.png" />
      </a>
    </div>
    <hr />
  </nav>
</header>
<!-- <section class="center-div pt-4 mx-width-700">
    <div class="card" *ngIf="isShowModal">
        <img id="appOpenImg" src="../../../../../assets/image/cloud-computing.jpg" alt="">
         <div class="instalation-message py-3">
          <div class="text-center">
            <p class="appOpenHeading" *ngIf="emailLink == '1'">{{'surveyLinkAppMes'|translate}}</p>
            <p class="appOpenHeading" *ngIf="emailLink == '0'">{{'emailVerifyAppMes'|translate}}</p>      
           </div>
           <p class="appOpenDescription">{{'fallbackCommonMes'|translate}}</p>
           <div class="buttonContainer">
             <button class="acceptButton" (click)="yesInstallApp()">{{'yes'|translate}}</button>
           <button class="declineButton" (click)="noInstallApp()">{{'no'|translate}}</button>
           </div>
         </div>
    </div>
</section> -->