import { Component, OnInit } from '@angular/core';
import { userApi } from 'src/app/base/model/constants/ApiEndPoints';
import { PanelGuId } from 'src/app/base/model/constants/AppConstants';
import { BaseService } from 'src/app/base/service/base.service';

@Component({
  selector: 'app-automobile',
  templateUrl: './automobile.component.html'
})
export class AutomobileComponent implements OnInit {

  constructor(private _baseService: BaseService) { }
  items: string[] = [
    'Do you have a car', 'your car name is BMW', 'do you have any automobile'
  ]


  profileQuestion;
  ngOnInit() {

    const panelGuid = PanelGuId.OpinionEdge;
    const countryId = 2;
    const languageId = 1;
    const categoryId = 3;
    const panelistId = localStorage.getItem('panelistId')
    this._baseService.get(userApi.getProfileCatQsn +
      `?panelGuid=${panelGuid}&countryId=${countryId}&languageId=${languageId}&categoryId=${categoryId}&panelistId=${panelistId}`
    ).then((res: any) => {
      console.log(res)
      this.profileQuestion = res.data
    })
  }
}
