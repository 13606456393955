import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-custom-toast',
  templateUrl: './custom-toast.component.html'
})
export class CustomToastComponent {
  constructor(private router: Router) { }
  isShowToastr: boolean = true;
  ngOnInit() {
    setTimeout(() => {
      this.isShowToastr = false;
    }, 2000);
  }
  navigateToVerifyEmail() {
    this.router.navigate(['/login/body/personal-detail']);
  }
}
