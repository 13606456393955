import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-app-installation',
  templateUrl: './app-installation.component.html'
})
export class AppInstallationComponent implements OnInit {
  constructor(
     private router: Router,
     private toastr:ToastrService,
     private deviceDetector: DeviceDetectorService
    ){}
  getBulkToken = localStorage.getItem('bulk_token');
  emailLink = localStorage.getItem('email_Link');
  isShowModal;
  ngOnInit() {
    localStorage.setItem('isShowPops','true')
    if(this.emailLink == '1'){
      this.router.navigate(['/survey-landing'], { queryParams: { token: this.getBulkToken } })
      return;
    } 
    this.router.navigate(['login/body/dashboard'])
  }

  yesInstallApp(){
    const device = this.deviceDetector.os.toLowerCase();
    if (device === 'ios') {
     window.location.href = 'https://apps.apple.com/us/app/opinion-edge/id6446476343'
    } else if (device === 'android'){
      window.location.href = 'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.unipanel'
    }
  }

  // noInstallApp(){
  //   localStorage.setItem('noIApp', 'true');
  //   if(this.emailLink == '1'){
  //     this.router.navigate(['/survey-landing'], { queryParams: { token: this.getBulkToken } })
  //     return;
  //   } 
  //   this.router.navigate(['login/body/dashboard'])
  // }

}
